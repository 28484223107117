import moment from 'moment';

import { dollarsToCents } from '../misc';
import processSaleItem from './processSaleItem';

export default function processHistoricalOrder(
  rawOrder: RawHistoricalOrder,
): HistoricalOrder {
  const items = rawOrder.Items.map(item =>
    processSaleItem(item, rawOrder.MIM ?? false),
  );

  // hack to figure out if the raw "Points" number is points or rewards
  const isRewards =
    !!rawOrder.Points &&
    !rawOrder.Items.some(rawItem => !!rawItem.RedeemPoints);

  return {
    id: rawOrder.ID,
    folioID:
      rawOrder.FolioID || rawOrder.FolioID === 0 ? rawOrder.FolioID : undefined,
    moneyPrice: dollarsToCents(rawOrder.Total),
    pointsAward: (!isRewards && rawOrder.Points) || undefined, // not sure if this needs to be turned into an integer?
    rewardsAward: (isRewards && dollarsToCents(rawOrder.Points)) || undefined,
    locationId: String(rawOrder.StoreID),
    items,
    orderDate: moment(rawOrder.OrderDate).format('D/M/YYYY'),
    pickupTime: moment(rawOrder.PickupTime).format('MMMM Do YYYY, h:mm:ss a'),
    saleType: rawOrder.SaleType,
    orderTime: moment(rawOrder.OrderDate).format('h:mma'),
    posSaleID: rawOrder.PosSaleID,
  };
}
