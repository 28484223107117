import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Text from '../components/Text';
import { Row, Col } from 'reactstrap';
import Filters from '../components/Filters';
import TouchableOpacity from '../components/TouchableOpacity';
import moment from 'moment';
import SinglePastOrder from '../components/SinglePastOrder';
import { showLoyaltyHeaders } from '../slices/loyaltyHeaders';
import { IoIosArrowBack } from 'react-icons/io';
import ReorderButton from '../components/ReorderButton';
import LoyaltyTitle from '../components/LoyaltyTitle';

const { useFormattedCurrency } = OrderingHooks;
const { getHistoricalOrders } = OrderingSelectors;

export const PAST_ORDERS_SCREEN_ROUTE = '/past-orders';

const Order: React.FC<{ order: EnhancedHistoricalOrder }> = ({ order }) => {
  const { t } = useTranslation();
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);
  const orderTotal = useFormattedCurrency({ cents: order.moneyPrice });
  return (
    <div className="p-3 my-2" style={{ backgroundColor: 'whitesmoke', borderRadius: 5 }}>
      <Row>
        <Col xs="9">
          <div>
            <Text
              // themeKey="loyaltySaleType"
              value={t(`saleType.${order.saleType}.name`).toUpperCase()}
            />{' '}
            {order.locationName && (
              <>
                <Text themeKey="loyaltyStoreLocation" value={order.locationName.toUpperCase()} />{' '}
              </>
            )}
            <Text themeKey="loyaltyOrderDate" value={order.orderDate} />
            <div>
              {order.items.map(item => (
                <div key={item.id}>
                  <Text themeKey="loyaltyOrderItems" value={item.name} />{' '}
                  <Text themeKey="loyaltyOrderItems" value={'x' + item.quantity.toString()} />
                </div>
              ))}
              <Text
                // themeKey="loyaltyOrderTotal"
                value={orderTotal}
              />
            </div>
          </div>
        </Col>
        <Col xs="3" className=" position-relative">
          {!isOnlyLoyaltyEnabled && <ReorderButton order={order} />}
        </Col>
      </Row>
    </div>
  );
};

const HistoricalOrder = () => {
  const [selectedOrder, setSelectedOrder] = useState<EnhancedHistoricalOrder | null>(null);
  const selectedFilter = useAppSelector(state => state.loyaltyFilters);
  const dispatch = useAppDispatch();
  const date = useAppSelector(state => state.loyaltyDateFilters);

  const deviceType = useAppSelector(state => state.deviceType);
  const historicalOrders = useAppSelector(getHistoricalOrders);
  let filteredHistoricalOrders = selectedFilter
    ? historicalOrders?.filter(order => order.saleType === selectedFilter)
    : historicalOrders;

  if (date) {
    filteredHistoricalOrders = filteredHistoricalOrders?.filter(order => {
      return (
        moment(order.orderDate, 'DD/MM/YYYY').format('DD/MM/YYYY') ===
        moment(date).format('DD/MM/YYYY')
      );
    });
  }

  return (
    <div>
      {!selectedOrder && (
        <>
          <LoyaltyTitle title="pastOrdersHeader" />

          {deviceType === 1 && <Filters />}
        </>
      )}
      {selectedOrder ? (
        <div>
          <TouchableOpacity
            style={{ paddingTop: 10 }}
            onClick={() => {
              dispatch(showLoyaltyHeaders(true));
              setSelectedOrder(null);
            }}
          >
            <IoIosArrowBack style={{ fontSize: 35 }} />
          </TouchableOpacity>
          <SinglePastOrder selectedOrder={selectedOrder} />
        </div>
      ) : (
        filteredHistoricalOrders?.map(order => (
          <TouchableOpacity
            key={order.id}
            onClick={() => {
              setSelectedOrder(order);
              dispatch(showLoyaltyHeaders(false));
            }}
          >
            <Order order={order} />
          </TouchableOpacity>
        ))
      )}
    </div>
  );
};

const PastOrdersScreen = () => {
  return (
    <div className=" position-relative">
      <HistoricalOrder />
    </div>
  );
};

export default PastOrdersScreen;
