import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SubSection from './SubSection';
import { OrderingSelectors } from 'polygon-ordering';
import { RiArrowRightSLine } from 'react-icons/ri';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { LOCATION_MODAL_ID } from '../modals/LocationModal';

const { getLocation, getSaleType } = OrderingSelectors;

const LocationSummary = () => {
  const dispatch = useAppDispatch();
  const saleType = useAppSelector(getSaleType);
  const unenhancedLocation = useAppSelector(getLocation);
  return (
    <div style={{ padding: '0 3.5em' }}>
      <SubSection
        title={unenhancedLocation?.name!}
        action={() => {
          dispatch(
            setCurrentModal({
              modalId: LOCATION_MODAL_ID,
              params: { saleType, allowRedirect: false },
            }),
          );
        }}
        ActiveIcon={() => <RiArrowRightSLine />}
      />
    </div>
  );
};

export default LocationSummary;
