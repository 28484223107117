import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';

import { TEXT_PROPERTIES } from '../utils/theme';

import { OrderingSelectors } from 'polygon-ordering';

import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { enqueueWarningSnackbar } from '../utils/snackbar';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

import QuantityControl from './QuantityControl';
import { TFunction } from 'i18next';

import CheckBox from './CheckBox';
import RadioCheck from './RadioCheck';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Energy from './Energy';

const { getOpenPurchase, getNestedItemStockBalancesData } = OrderingSelectors;

interface ChoiceWithQuantity extends Choice {
  quantity?: number;
  category?: string;
}

const Tag: React.FC<{
  choice: ChoiceWithQuantity;
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoice) => void;
  t: TFunction;
  p: (key: string, properties: ThemeProperties) => React.CSSProperties;
  stockBalanceData: StockBalanceData;
  purchaseQuantity: number;
}> = ({ choice, adjustChoice, choiceSet, t, p, stockBalanceData, purchaseQuantity = 1 }) => {
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  const baseTextSize = { fontSize: isMobileDevice ? 14 : 16 };

  const baseTagStyle = combineStyles(
    p('tagButton', TEXT_PROPERTIES),
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  const primaryTagStyle = combineStyles(
    baseTagStyle,
    Boolean(choice.quantity) && {
      textShadow: `0px 0px 1px ${baseTagStyle.color || 'black'}`,
    },
  );

  const emphasised = { fontWeight: '900' }; // maximum

  const hiddenTagStyle = combineStyles(baseTagStyle, emphasised, { color: 'transparent' });

  const labelBase = String(choice.name);

  // let stockBalanceData = calculateStockBalanceData(choice.plucode);
  // useEffect(() => {
  //   stockBalanceData = calculateStockBalanceData(choice.plucode);
  // }, [stockBalances, stagedPurchases]);

  // const revertSoldOutThresholdToLowInStock =
  //   stockBalanceData.stockBalanceThreshold === 'STOCK_BALANCE_THRESHOLD_0' &&
  //   stockBalanceData.itemBalance !== 0
  //     ? 'STOCK_BALANCE_THRESHOLD_1'
  //     : stockBalanceData.stockBalanceThreshold;

  const thresholdLabelColor = combineStyles(
    Boolean(stockBalanceData.stockBalanceThreshold) &&
      p(stockBalanceData.stockBalanceThreshold!, ['color']),
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  const checkIfDisabled = stockBalanceData.soldOut;

  // NOTE: all this comlication is to ensure that the tag doesn't resize
  // as you select it/increase the quantity.

  // This is done by having hidden text that is approximately the same size,
  // which is removed when the quantity is > 0

  const displayGrid = choiceSet.displayType === 'grid';
  let label = (
    <Text
      style={combineStyles(
        {
          display: 'flex',
          alignItems: 'center',
        },
        baseTextSize,
        !choice?.category && choice.quantity && p('itemChoiceActive', TEXT_PROPERTIES),
      )}
    >
      {labelBase}

      {stockBalanceData.stockBalanceThreshold && (
        <Text style={combineStyles(primaryTagStyle, emphasised, thresholdLabelColor)}>
          {' - ' + t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
        </Text>
      )}
    </Text>
  );

  const [selected, setSelected] = useState(false);

  // check nestedchoicesettags.tsx for comments on this stuff

  const isRadioCheck = choiceSet.max === 1 && (choiceSet.min === 1 || choiceSet.required);

  const effectiveIndividualMax = Math.min(
    choiceSet.individualMax || choiceSet.max || 1,
    choiceSet.max || 1,
  );
  const showQuantityControl = effectiveIndividualMax > 1;

  const isMaxQuantity =
    choiceSet.quantity === choiceSet.max || choice.quantity === effectiveIndividualMax;

  const choiceClick = (increase?: boolean) => {
    const quantityClicked = increase !== undefined;

    const shouldClear = quantityClicked
      ? !increase && choice.quantity === 1
      : selected && !isRadioCheck;
    const shouldIncrease = increase ?? (isRadioCheck || !isMaxQuantity);
    const noChange = !shouldClear && !quantityClicked && !shouldIncrease;

    if (
      !shouldClear &&
      shouldIncrease &&
      (choice.quantity! > 0 ? choice.quantity! * purchaseQuantity : purchaseQuantity) >
        stockBalanceData.cartAdjustedBalance!
    ) {
      enqueueWarningSnackbar(t('stagedChoiceSetItemQuantityWarningMessage'));
    } else if (!noChange) {
      adjustChoice({
        targetChoiceSetId: choiceSet.key,
        targetChoiceId: choice.id,
        clear: shouldClear,
        decrease: !shouldIncrease,
      });
    }
  };

  useEffect(() => {
    if ((choice.quantity || 0) < 1) {
      setSelected(false);
    } else {
      setSelected(true);
    }
  }, [choice.quantity]);

  const priceShowing =
    (choice.baseMoneyPrice &&
      (!choiceSet.free ||
        ((!choiceSet.max || choiceSet.max > choiceSet.free) &&
          choiceSet.quantity > choiceSet.free)) &&
      choice.baseMoneyPrice) ||
    0;

  const price = useFormattedCurrency({ cents: priceShowing });

  return (
    <div
      style={{
        ...styles.tag,
        flexDirection: displayGrid ? 'column' : 'row',
        justifyContent: displayGrid ? 'flex-start' : 'space-between',
      }}
    >
      <div style={{ ...styles.tagLeft, flexDirection: displayGrid ? 'column' : 'row' }}>
        {isRadioCheck ? (
          <RadioCheck
            label={label}
            checked={Boolean(choice.quantity)}
            onClick={() => choiceClick()}
            displayType={choiceSet.displayType}
            choice={choice}
            disabled={checkIfDisabled || false}
            price={priceShowing}
          />
        ) : (
          <CheckBox
            choice={choice}
            label={label}
            checked={selected}
            onClick={() => choiceClick()}
            displayType={choiceSet.displayType}
            disabled={checkIfDisabled || false}
            price={priceShowing}
          />
        )}
      </div>
      {!displayGrid && (
        <div style={{ flex: 1 }}>
          {priceShowing > 0 && (
            <Text style={{ textAlign: 'right', zIndex: -1 }}>{`+ ${price}`}</Text>
          )}
        </div>
      )}

      <div>
        {selected && showQuantityControl ? (
          <QuantityControl
            quantity={choice.quantity || 0}
            containerStyle={{ gap: isMobileDevice ? 36 : 38 }}
            iconContainerStyle={{}}
            textStyle={baseTextSize}
            increaseQty={() => choiceClick(true)}
            decreaseQty={() => choiceClick(false)}
            increaseEnabled={!isMaxQuantity}
            maxQuantity={stockBalanceData.maxAvailableQuantityChoice}
            minQuantity={0}
            buttonSize={isMobileDevice ? 25 : 30}
          />
        ) : choice.kilojoules ? (
          <Energy kilojouleCount={choice.kilojoules} />
        ) : null}
      </div>
    </div>
  );
};

const ChoiceSetTags: React.FC<{
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoice) => void;
}> = ({ choiceSet, adjustChoice }) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const openPurchase = useAppSelector(getOpenPurchase) as PurchaseWithTotals;

  const [stockBalanceDataMap] = useAppSelector(getNestedItemStockBalancesData(openPurchase));
  const displayGrid = choiceSet.displayType === 'grid';

  return (
    <div style={{ ...styles.mainContainer, flexDirection: displayGrid ? 'row' : 'column' }}>
      {choiceSet.choices.map((choice: Choice) => (
        <Tag
          key={choice.id}
          choice={choice}
          choiceSet={choiceSet}
          adjustChoice={adjustChoice}
          t={t}
          p={p}
          stockBalanceData={
            (stockBalanceDataMap && stockBalanceDataMap[choice.plucode]) || ({} as StockBalanceData)
          }
          purchaseQuantity={openPurchase.quantity}
        />
      ))}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px',
    gap: 10,
    maxWidth: '100%',
    flexWrap: 'wrap',
  },
  tag: {
    position: 'relative',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15,
  },

  clearButtonContainer: {
    position: 'absolute',
    borderRadius: 9999,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ChoiceSetTagOpacity: {
    opacity: 0.5,
  },
  tagLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
};

export default ChoiceSetTags;
