import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import {
  persistStore,
  persistReducer,
  createMigrate,
  createTransform,
  PersistConfig,
  PersistedState,
} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer, { PERSIST_KEYS } from './slices';
import { OrderingReducer } from 'polygon-ordering';
import { PERSIST_KEY } from './constants';
import { listenerMiddleware } from './app/listener';
import localforage from 'localforage';

const orderingTransform = createTransform(
  null,
  (outboundState: any, key) => {
    const newState = { ...outboundState };
    // Clear out selectedPaymentMethods on refresh & new orders
    newState.currentOrder.selectedPaymentMethods = [];
    return newState;
  },
  { whitelist: ['ordering'] },
);

const rootPersistConfig: PersistConfig<RootState> = {
  key: PERSIST_KEY,
  storage,
  whitelist: PERSIST_KEYS,
  transforms: [orderingTransform],

  version: 3, // update this if you change the redux store structure between versions, this should make redux-persist purge the data so that no errors occur
  // the details of this are based on the following migration function:
  // at the moment this will just purge the state if the version changes
  migrate: createMigrate({
    3: savedState => {
      // MIM/OLO3, need to clear the ordering portion because the menu structure has changed since OLO2
      // what happens if we rollback?
      const newState = {
        ...savedState,
        ordering: OrderingReducer(undefined, { type: '@@INIT' }),
      };
      return newState as unknown as PersistedState;
    },
  }),
  stateReconciler: autoMergeLevel1,
};

export type RootState = ReturnType<typeof rootReducer>;

const resettableRootReducer = (state: RootState, action: Action) => {
  if (action.type === '$RESET_ROOT_STATE') {
    storage.removeItem('persist:' + PERSIST_KEY);
    localforage.removeItem('persist:' + 'localForage');

    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer<RootState>(
  rootPersistConfig,
  resettableRootReducer as typeof rootReducer,
);

// NOTE: need to do a few extra steps to combine RTK with redux-persist
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);

// make the redux store easily accessible via the window global
// (including the dispatchInjector script)

window.redux_store = store;
