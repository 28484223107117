import React, { useState } from 'react';
import { RiArrowDropUpLine, RiArrowDropDownLine } from 'react-icons/ri';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { IoMdAlert } from 'react-icons/io';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';

import StandardButton from './StandardButton';
import Text from '../components/Text';
import { OrderingOperations, OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import cartChoiceSetCompactAlert from '../utils/cartChoiceSetCompactAlert';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { useTranslation } from 'react-i18next';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { PURCHASE_EDITOR_MODAL_ID } from '../modals/PurchaseEditorModalV2';
import { logEvent, viewProductDetailPage } from '../utils/analytics';
import EVENTS from '../constants/events';
import QuantityControl from './QuantityControl';
import TouchableOpacity from './TouchableOpacity';

const { useFormattedCurrency } = OrderingHooks;
import * as lodash from 'lodash';

const {
  updateSelectedPaymentMethods,
  adjustStagedPurchaseQuantity,
  removeStagedPurchase,
  openStagedPurchase,
} = OrderingOperations;

const {
  getCartContainsInvalidQuantities,
  getNestedItemStockBalancesData,
  getStagedPurchaseAvailability,
  getItemAvailabilityById,
  getStagedPurchases,
  getBrands,
  getRootCategory,
} = OrderingSelectors;

const SubLine = ({
  quantity = 0,
  label,
  amount,
  soldOut,
  itemPLU,
  cartHasInvalidItems,
  qtyUpdates,
  marginStyles,
}: {
  quantity?: number;
  label?: string;
  amount?: number;
  soldOut?: boolean | null;
  itemPLU?: string;
  cartHasInvalidItems?: any;
  qtyUpdates?: any;
  marginStyles?: boolean | false;
}) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const dollarAmount = useFormattedCurrency({ cents: Number(amount) });
  const availability = useAppSelector(getItemAvailabilityById(itemPLU));

  return (
    <div style={styles.subLine}>
      <div style={styles.left}>
        <div style={combineStyles(styles.subLineLeftContent, marginStyles && styles.choiceSetPush)}>
          <Text themeKey="stagedPurchaseSubLine">
            {`${quantity > 1 ? `${quantity} × ` : ''}${label}`}
            {soldOut ||
              (!availability && (
                <div style={styles.badgeAndMessageContainer}>
                  <IoMdAlert
                    style={combineStyles(
                      p('cartItemErrorAlert', ['color']),
                      styles.cartItemErrorAlert,
                    )}
                  />
                  <Text
                    themeKey="stagedPurchaseSubLine"
                    style={combineStyles(p('cartItemError', ['color']), styles.cartItemError)}
                  >
                    {t('itemOutOfStockMessage')}
                  </Text>
                </div>
              ))}

            {qtyUpdates?.hasOwnProperty(itemPLU) && cartHasInvalidItems && !soldOut && (
              <div style={styles.badgeAndMessageContainer}>
                <IoMdAlert
                  style={combineStyles(
                    p('cartItemWarningAlert', ['color']),
                    styles.cartItemWarningAlert,
                  )}
                />
                <Text
                  themeKey="stagedPurchaseSubLine"
                  style={combineStyles(p('cartItemWarning', ['color']), styles.cartItemWarning)}
                >
                  {t('itemQuantityWarningMessage', {
                    newItemQuantity: qtyUpdates[itemPLU!],
                    someLabel: label,
                  })}
                </Text>
              </div>
            )}
          </Text>
        </div>
      </div>

      {amount != null && amount !== 0 && (
        <Text themeKey="stagedPurchaseSubLine">{dollarAmount}</Text>
      )}
    </div>
  );
};

type IProps = {
  readOnly?: boolean;
  purchase?: PurchaseWithTotals;
  unavailablePurchase?: _Purchase;
  hideQuantityWhenSingular?: boolean;
  qtyUpdates: any;
};

const StagedPurchase: React.FC<IProps> = ({
  purchase,
  unavailablePurchase,
  readOnly,
  hideQuantityWhenSingular,
  qtyUpdates,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const iconStyle = p('stagedPurchaseButtonIcon', ['color', 'fontSize']);
  const hideQuantity = purchase && hideQuantityWhenSingular && purchase.quantity === 1;
  const dispatch = useAppDispatch();
  const cartHasInvalidItems = useAppSelector(getCartContainsInvalidQuantities);
  const brands = useAppSelector(getBrands);
  const rootCategory = useAppSelector(getRootCategory);

  const stagedPurchaseAvailability = useAppSelector(getStagedPurchaseAvailability(purchase));

  const isNestedItem = purchase?.choiceSets?.reduce(
    (acc, set) =>
      acc ||
      (lodash.has(set, 'nestedIngredients') && (set as ValidatedNestedChoiceSet).nestedIngredients),
    false,
  );
  const [stockBalanceDataMap, minBalanceStaged] = useAppSelector(
    getNestedItemStockBalancesData(
      purchase,
      isNestedItem ? (purchase?.choiceSelections as NestedChoiceSelections) : undefined,
    ),
  );
  const { item } = (purchase || unavailablePurchase) as PurchaseWithTotals;
  const stockBalanceData = (stockBalanceDataMap &&
    item?.plucode &&
    stockBalanceDataMap[item?.plucode]) as StockBalanceData | undefined;

  // const minBalanceStaged = useAppSelector(
  //   getMaxItemQuantityByStagedPurchase((purchase || unavailablePurchase) as PurchaseWithTotals),
  // );
  // const minBalanceStaged =
  //   purchase && calcMinBalanceStaged(purchase.choicesWithQuantity, quantityMap);

  const choicesAlert =
    purchase &&
    cartChoiceSetCompactAlert(
      purchase.choicesWithQuantity,
      //@ts-ignore
      cartHasInvalidItems.trackQuantityUpdates,
    );
  const [showDetails, setShowDetails] = useState(false);

  const sizeItem = purchase?.item.sizes.find(s => s.id === purchase.sizeId);

  const discountMoneyPrice = useFormattedCurrency({ cents: purchase?.discountedMoneyPrice });

  const categories = rootCategory?.subCategories || [];

  const brand = brands.find(brand => brand.id === purchase?.brandId)?.name || null;
  const category =
    categories.find(category =>
      lodash.map(category.items, 'id').find(id => id === purchase?.item.id),
    )?.name || null;

  const unavailableItemBasePrice = useFormattedCurrency({
    cents: unavailablePurchase?.item?.baseMoneyPrice,
  });

  // flatten the choices out of the choicesets in a nested item
  const flattenChoiceSets = (
    choiceSets: ChoiceSetWithQuantity[],
  ): ChoiceWithQuantity[] | undefined => {
    return choiceSets?.reduce((flattened, choiceSet) => {
      flattened.push(...choiceSet.choices.filter(c => !!c.quantity));

      return flattened;
    }, [] as ChoiceWithQuantity[]);
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.top}>
        <div style={styles.left}>
          <div>
            <Text
              themeKey="stagedPurchase"
              style={combineStyles(
                { overflowWrap: 'anywhere' },
                unavailablePurchase && p('unavailablePurchaseText', ['opacity']),
              )}
            >
              {purchase?.item.name ?? unavailablePurchase?.item?.name}
            </Text>
            {!stagedPurchaseAvailability && (
              <div>
                <IoMdAlert
                  style={combineStyles(p('cartItemError', ['color']), styles.cartItemErrorAlert)}
                />
                <Text
                  themeKey="stagedPurchaseSubLine"
                  style={combineStyles(p('cartItemError', ['color']), styles.cartItemWarning)}
                >
                  {t('itemUnavailable')}
                </Text>
              </div>
            )}
          </div>
          {purchase?.choicesWithQuantity && purchase?.choicesWithQuantity.length > 0 && (
            <TouchableOpacity
              style={{
                fontSize: 12,
                opacity: 0.7,
              }}
              onClick={() => setShowDetails(preState => !preState)}
            >
              {t('showItemDetails')}
              {showDetails ? <RiArrowDropUpLine size={20} /> : <RiArrowDropDownLine size={20} />}
            </TouchableOpacity>
          )}

          {showDetails && (
            <div style={styles.subLines}>
              {/* size option */}
              {!!sizeItem && (
                <SubLine
                  label={sizeItem.name}
                  amount={
                    sizeItem.baseMoneyPrice -
                    (purchase?.item.minSizeBaseMoneyPrice ?? purchase?.baseMoneyPrice ?? 0)
                  }
                  itemPLU={sizeItem.id}
                />
              )}
              {/* item parent */}
              {purchase?.choicesWithQuantity?.map((choice, index) => (
                <React.Fragment key={index}>
                  <SubLine
                    label={choice.name}
                    quantity={choice.quantity}
                    amount={choice.moneyPrice}
                    soldOut={stockBalanceData?.soldOut}
                    itemPLU={choice.id}
                    //@ts-ignore
                    cartHasInvalidItems={cartHasInvalidItems.cartContainsInvalidQuantities}
                    // This can be a safe guard for the CompleteOrderScreen Bug qtyUpdates={qtyUpdates ? qtyUpdates : {}}
                    qtyUpdates={qtyUpdates}
                  />
                  {/* item choicesets / ingredients */}
                  {'choiceSets' in choice &&
                    flattenChoiceSets((choice as NestedChoiceWithQuantity).choiceSets)?.map(
                      (choice: any, index: number) => {
                        return (
                          <SubLine
                            key={index}
                            label={choice.name}
                            quantity={choice.quantity}
                            amount={choice.moneyPrice}
                            soldOut={stockBalanceData?.soldOut}
                            itemPLU={choice.id}
                            //@ts-ignore
                            cartHasInvalidItems={cartHasInvalidItems.cartContainsInvalidQuantities}
                            // This can be a safe guard for the CompleteOrderScreen Bug qtyUpdates={qtyUpdates ? qtyUpdates : {}}
                            qtyUpdates={qtyUpdates}
                            marginStyles
                          />
                        );
                      },
                    )}
                </React.Fragment>
              ))}

              {!!purchase?.moneyDiscount && (
                <SubLine label={t('stagedPurchaseDiscount')} amount={purchase.moneyDiscount * -1} />
              )}
            </div>
          )}

          <div style={styles.buttons}>
            {!stockBalanceData?.soldOut && !unavailablePurchase && (
              <StandardButton
                label={t('button.stagedPurchase.customise')}
                onClick={() =>
                  purchase &&
                  dispatch(openStagedPurchase({ purchaseId: purchase.id })).then(() => {
                    dispatch(
                      setCurrentModal({
                        modalId: PURCHASE_EDITOR_MODAL_ID,
                        preserveReturnModalDetails: true,
                      }),
                    );

                    logEvent(EVENTS.OPEN_EXISTING_PURCHASE);
                    viewProductDetailPage(
                      purchase.item,
                      purchase.quantity,
                      purchase.choicesWithQuantity,
                      brand,
                      category,
                    );
                  })
                }
                // LeftIconComponent={RiPencilFill}
                themeKey="stagedPurchaseButton"
                containerStyle={styles.button}
                // leftIconStyle={iconStyle}
                invisibleContainer
              />
            )}

            {unavailablePurchase && (
              <Text
                themeKey="stagedPurchaseButton"
                value={t('button.stagedPurchase.unavailable')}
                style={combineStyles(styles.button, p('unavailablePurchaseText', ['opacity']))}
              />
            )}

            <StandardButton
              label={t('button.stagedPurchase.remove')}
              onClick={() => {
                const id = purchase?.id ?? unavailablePurchase?.id;
                if (!id) return;
                dispatch(removeStagedPurchase(id));
                dispatch(updateSelectedPaymentMethods({}));

                logEvent(EVENTS.REMOVE_PURCHASE);
              }}
              // LeftIconComponent={RiCloseCircleFill}
              themeKey="stagedPurchaseButton"
              labelStyle={combineStyles(p('unavailablePurchaseButton', TEXT_PROPERTIES))}
              containerStyle={styles.button}
              // leftIconStyle={iconStyle}
              invisibleContainer
            />
          </div>
        </div>

        <div style={styles.right}>
          <Text
            themeKey="stagedPurchase"
            style={unavailablePurchase && combineStyles(p('unavailablePurchaseText', ['opacity']))}
          >
            {(purchase && discountMoneyPrice) ??
              (unavailablePurchase?.item &&
                unavailablePurchase?.item?.baseMoneyPrice &&
                `From ${unavailableItemBasePrice}`)}
          </Text>

          {hideQuantity ? null : readOnly ? (
            <Text themeKey="stagedPurchase">
              {purchase?.quantity ?? unavailablePurchase?.quantity}&nbsp;
            </Text>
          ) : (
            <>
              {(purchase || unavailablePurchase) && (
                <QuantityControl
                  quantity={(purchase?.quantity ?? unavailablePurchase?.quantity)!}
                  maxQuantity={minBalanceStaged}
                  iconContainerStyle={{ fontSize: 20, color: 'red' }}
                  textStyle={{ fontSize: 16 }}
                  buttonSize={22}
                  increaseQty={
                    purchase &&
                    (() =>
                      dispatch(
                        adjustStagedPurchaseQuantity({
                          purchaseId: purchase.id,
                          adjustment: 0,
                          newQuantity: Number(purchase.quantity + 1),
                        }),
                      ))
                  }
                  decreaseQty={
                    purchase &&
                    (() =>
                      dispatch(
                        adjustStagedPurchaseQuantity({
                          purchaseId: purchase.id,
                          adjustment: 0,
                          newQuantity: Number(purchase.quantity - 1),
                        }),
                      ))
                  }
                  increaseEnabled={!unavailablePurchase}
                  decreaseEnabled={!unavailablePurchase}
                />
              )}
            </>
          )}
        </div>
      </div>

      {!readOnly && purchase && (
        <div>
          {stockBalanceData?.soldOut && (
            <div style={styles.badgeAndMessageContainer}>
              <IoMdAlert
                style={combineStyles(p('cartItemErrorAlert', ['color']), styles.cartItemErrorAlert)}
              />
              <Text
                themeKey="stagedPurchaseSubLine"
                style={combineStyles(p('cartItemError', ['color']), styles.cartItemError)}
              >
                {t('itemOutOfStockMessage')}
              </Text>
            </div>
          )}
          {qtyUpdates?.hasOwnProperty(purchase.item.id) &&
            purchase.choicesWithQuantity.length === 0 &&
            //@ts-ignore
            cartHasInvalidItems.cartContainsInvalidQuantities &&
            !stockBalanceData?.soldOut && (
              <div style={styles.badgeAndMessageContainer}>
                <IoMdAlert
                  style={combineStyles(
                    p('cartItemWarningAlert', ['color']),
                    styles.cartItemWarningAlert,
                  )}
                />
                <Text
                  themeKey="stagedPurchaseSubLine"
                  style={combineStyles(p('cartItemWarning', ['color']), styles.cartItemWarning)}
                >
                  {t('itemQuantityWarningMessage', {
                    newItemQuantity: qtyUpdates[purchase.item.id],
                    someLabel: purchase.item.name,
                  })}
                </Text>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    //marginBottom: 20,
  },

  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretched',
  },

  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  /* middle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  }, */
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  subLines: {
    paddingLeft: 5,
    paddingTop: 10,
  },

  subLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 6,
    gap: 10,
  },
  subLineLeftContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  choiceSetPush: {
    margin: '0px 0px 0px 12px',
  },

  quantitySelect: {
    fontFamily: 'Roboto',
    marginRight: 7,
    // marginBottom: 5,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 0,
    paddingTop: '0.5em',
  },
  button: {
    marginRight: 20,
  },
  adjust: {
    marginLeft: 30,
  },
  cartItemError: {
    paddingLeft: 5,
  },
  cartItemErrorAlert: {
    fontSize: 20,
  },
  cartItemWarning: {
    paddingLeft: 5,
  },
  cartItemWarningAlert: {
    fontSize: 20,
  },

  badgeAndMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: -2,
  },
  spanCartSublineMessage: {
    marginBottom: -5,
    marginLeft: 5,
  },
};

export default StagedPurchase;
