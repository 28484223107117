import { createSelector } from '@reduxjs/toolkit';
import getMenu from './getMenu';
import getItems from './getItems';
import getNestedItemStockBalancesData from './getNestedItemStockBalancesData';
import {
  getUpsellDisplayLimit,
  getUpsellUnavailableDisplayType,
} from './config';
import validateUpsellItems from '../utils/ordering/upsellItemsValidate';
import { UPSELL_ITEM_DISPLAY_TYPE } from '../constants';

export default (item: Item | undefined) =>
  createSelector(
    [
      getMenu,
      getItems,
      getNestedItemStockBalancesData(undefined),
      getUpsellDisplayLimit,
      getUpsellUnavailableDisplayType,
    ],
    (
      menu,
      items,
      [stockBalanceDataMap],
      upsellAmountByItem = 3,
      unavailableItemDisplay,
    ): (Item & { upsellAvailable: boolean })[] => {
      if (!item) return [];
      if (!menu) return [];
      const { upsellChoiceSets } = item;
      if (!upsellChoiceSets || !((upsellChoiceSets || []).length > 0) || !items)
        return [];
      const { choiceSets } = menu;

      const set = choiceSets[upsellChoiceSets[0]];
      if (!set) return [];
      //upsell item can not be item itself
      const upSellItems = set.choices
        .filter(choice => choice.id !== item.id)
        .map(choice => items[choice.id]);

      const validatedUpSellItems = validateUpsellItems(
        upSellItems,
        stockBalanceDataMap,
      );

      const result =
        unavailableItemDisplay === UPSELL_ITEM_DISPLAY_TYPE.GREYOUT
          ? validatedUpSellItems
          : validatedUpSellItems?.filter(item => item.upsellAvailable);

      return (result || []).slice(0, upsellAmountByItem);
    },
  );
