import { createAsyncThunk } from '@reduxjs/toolkit';
import getLocationId from '../selectors/getLocationId';
import getItems from '../selectors/getItems';
import { fetchPOSPoints } from './fetchPOSPoints';
import { setMember } from './setMember';
import { addToShadowCart } from '../operations';
import { FAILURE_REASON } from '../constants';
import { stagePurchaseFromItem } from './stagePurchaseFromItem';
import { addOffers } from '../reducers/currentOrder/offers';
import { setSelectedOffer } from '../reducers/currentOrder/selectedOffer';

export const applyBarcode = createAsyncThunk(
  '$applyBarcode',
  async (
    data: {
      barcode: string;
      locationId?: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { barcode, locationId: actionLocationId } = data;

    let reason: ApplyBarcodeFailureReason;

    try {
      const locationId =
        actionLocationId || getLocationId(getState() as EntireFrontendState);

      if (!locationId) {
        throw new Error('no location id');
      }

      const { member, offer, giftCard, itemDetails } = await dispatch(
        fetchPOSPoints({ barcode, locationId }),
      ).unwrap();

      if (!member && !giftCard) {
        reason = FAILURE_REASON.MEMBER_DETAILS_NOT_FOUND;

        throw new Error("valid barcode but member doesn't exist");
      }

      if (member) {
        await dispatch(setMember({ member }));
      }

      if (offer) {
        dispatch(addOffers([offer]));
        dispatch(
          setSelectedOffer({
            offerId: offer.id,
            userAdded: true, // TODO: test this
          }),
        );
      }

      let itemAdded;

      if (itemDetails) {
        const allItems: Items | undefined = getItems(
          getState() as EntireFrontendState,
        );

        if (!allItems) {
          // no menu data yet, add item to shadow cart

          const shadowItem: ShadowItem = {
            plucode: itemDetails.id,
            name: itemDetails.name,
            quantity: 1,
            choiceSets: {},
          };

          dispatch(addToShadowCart([shadowItem]));
        } else {
          const itemInMenuData = allItems[itemDetails.id];

          if (!itemInMenuData) {
            reason = FAILURE_REASON.PLU_NOT_FOUND;

            throw new Error('could not find item');
          }

          await dispatch(stagePurchaseFromItem({ itemId: itemDetails.id }));
          itemAdded = true;
        }
      }

      return {
        member,
        offer,
        giftCard,
        itemDetails,
        itemAdded,
      };
    } catch (e) {
      console.error('Apply barcode failed', { e, reason });
      return rejectWithValue({ e, reason });
    }
  },
);
