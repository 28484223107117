// this whole file needs refactoring (TODO), I added the themekey stuff later as the colours were fixed

import React from 'react';
import Avatar from '@mui/material/Avatar';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';
const { getTagDisplayType } = OrderingSelectors;

const TagAllergenDietary: React.FC<{
  option: TagAllergenDietary | undefined;
  size?: number;
  backgroundColor?: string;
  label?: boolean;
  themeKey?: string;
}> = ({ option, size = 30, backgroundColor, label, themeKey = 'tagWarning' }) => {
  if (!option) return null;
  const p = useAppSelector(getThemeLookup);
  const tagName = option?.short_name?.toUpperCase();
  const tagDisplayType = useAppSelector(getTagDisplayType);

  return tagName ? (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
      {option.image_url && (tagDisplayType === 'IMAGE' || tagDisplayType === 'BOTH') && (
        <img src={option.image_url} style={{ height: size, width: size }} />
      )}

      {(tagDisplayType === 'ABBREVIATION' || tagDisplayType === 'BOTH') && (
        <Avatar
          sx={{
            width: size,
            height: size,
            bgcolor: backgroundColor ?? p(themeKey, ['backgroundColor']).backgroundColor,
          }}
        >
          <Text style={p(themeKey, TEXT_PROPERTIES)}>{tagName}</Text>
        </Avatar>
      )}

      {label && <Text style={{ textTransform: 'capitalize' }}>{option.display_name}</Text>}
    </div>
  ) : null;
};

export default TagAllergenDietary;
