import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import CreditCardProvider from './CreditCardProvider';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';
import SavedCardProvider from './SavedCardProvider';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import ApplePayProvider from './ApplePayProvider';
import AppleGooglePayButtons from './AppleGooglePayButtons';
import { useFormikContext } from 'formik';
import GooglePayProvider from './GooglePayProvider';
import { OrderingSelectors } from 'polygon-ordering';
import { setPaymentMethod } from '../slices/paymentMethod';
import GiftcardSurcharge from './GiftcardSurcharge';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import combineStyles from '../utils/combineStyles';
import ReCAPTCHA from 'react-google-recaptcha';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setGoogleReCaptcha } from '../slices/googleReCaptcha';
import PaymentProvider from '../components/PaymentProvider';

const { getMember } = OrderingSelectors;

const SuperPaymentForm = ({
  route,
  notRequired = [],
}: {
  route: string;
  notRequired?: string[];
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const paymentFormFilled = useAppSelector(state => state.paymentFormFilled);
  const paymentFormInUse = useAppSelector(state => state.paymentFormInUse);
  const paymentMethod = useAppSelector(state => state.paymentMethod);
  const savedCards = useAppSelector(state => state.savedCards);
  const enableReCaptcha = useAppSelector(state => state.config.enableReCaptcha);
  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const reCaptchaToken = useAppSelector(state => state.googleReCaptcha);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  //reset payment method on mount
  useEffect(() => {
    if (paymentMethod) {
      dispatch(setPaymentMethod(null));
    }
  }, []);

  const member = useAppSelector(getMember);

  const formik = useFormikContext<FormikFields>();

  // check whether the form is filled and error free then disable
  const formDisabled =
    !formik.isValid ||
    formik.isSubmitting ||
    Object.entries(formik.values)
      .filter(pair => !notRequired.includes(pair[0]))
      .some(pair => pair[1] === '');

  const showAppleGooglePay =
    (paymentMethod?.method === PAYMENT_METHOD.GOOGLE_PAY ||
      paymentMethod?.method === PAYMENT_METHOD.APPLE_PAY) &&
    !formDisabled;

  const buttonLabel =
    route === 'giftCardTopUp' || route === 'memberCashTopUp'
      ? t('button.topupButton')
      : t('button.purchaseButton');

  const giftcardRoutes = [
    'memberPurchaseGiftCardForOthers',
    'nonMemberPurchaseGiftCardForOthers',
    'memberPurchaseGiftCardForSelf',
    'nonMemberPurchaseGiftCardForSelf',
  ];
  return (
    <div>
      <PaymentProvider shouldHaveMerchantAccount>
        {member &&
          savedCards?.map(savedCard => (
            <SavedCardProvider key={savedCard.token} savedCard={savedCard} route={route} />
          ))}
      </PaymentProvider>

      <PaymentProvider>
        <CreditCardProvider route={route} />
        <ApplePayProvider />
        <GooglePayProvider />

        {giftcardRoutes.includes(route) && <GiftcardSurcharge />}

        <div
          style={combineStyles({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: 15,
            justifyContent: 'center',
          })}
        >
          {enableReCaptcha && !reCaptchaToken ? (
            <ReCAPTCHA
              sitekey={reCaptchaSiteKey!}
              onChange={token => {
                dispatch(setGoogleReCaptcha(token));
              }}
              onErrored={() => {
                setGoogleReCaptcha(null);
                enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
              }}
              size={deviceTypeMobile ? 'compact' : 'normal'}
            />
          ) : null}
        </div>

        {showAppleGooglePay && (
          <div style={{ marginTop: 20 }}>
            <AppleGooglePayButtons route={route} />
          </div>
        )}
      </PaymentProvider>

      {!showAppleGooglePay && (
        <div style={{ marginTop: 20 }}>
          <StandardButton
            disabled={
              formDisabled ||
              paymentFormInUse ||
              !paymentFormFilled ||
              (enableReCaptcha && !reCaptchaToken)
            }
            onClick={() => {
              dispatch(setPaymentFormInUse(true));
            }}
            themeKey="loyaltyButton"
            containerStyle={styles.button}
            label={buttonLabel}
            showSpinner={paymentFormInUse}
          />
        </div>
      )}
    </div>
  );
};
const styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default SuperPaymentForm;
