import { createSelector } from 'reselect';

import getTable from './getTable';

export default createSelector([getTable], table => ({
  TableNumber: String(table),
  PrinterSettings: {
    HidePickupTime: true,
  },
}));
