import { createSelector } from 'reselect';

import getBufferReadyToApply from './getBufferReadyToApply';
import getStagedPurchases, { $getStagedPurchases } from './getStagedPurchases';
import getSelectedOffer, { $getSelectedOffer } from './getSelectedOffer';
import getSelectedOfferHasEffect, {
  $getSelectedOfferHasEffect,
} from './getSelectedOfferHasEffect';

import { BUFFER_WARNING_TYPE } from '../constants';
import getBuffer from './getBuffer';
import getAllLocations from './getAllLocations';
import getMenu, { $getMenu } from './getMenu';

const isChoiceSetChangeErrorDetected = (
  newPurchases: PurchaseWithTotals[],
  menu: Menu | undefined,
) => {
  const userPurchaseChoicesetKeys = newPurchases.flatMap(obj =>
    Object.keys(obj.choiceSelections!),
  );

  console.log('userPurchaseChoicesetKeys : ', userPurchaseChoicesetKeys);

  const userPurchaseChoicesetValues = newPurchases
    .flatMap(obj => Object.values(obj.choiceSelections!))
    .flat();

  console.log('userPurchaseChoicesetValues : ', userPurchaseChoicesetValues);

  let allChoiceIds: any = [];

  const choiceValueToKeyMapper: any = {};

  newPurchases.forEach(purchase => {
    const selections = purchase.choiceSelections;
    for (const selection in selections) {
      if (Array.isArray(selections[selection])) {
        (selections[selection] as any[]).forEach(
          (id: any) => (choiceValueToKeyMapper[id] = selection),
        );
      }
    }
  });

  console.log('mapper : ', choiceValueToKeyMapper);

  userPurchaseChoicesetKeys.forEach(key => {
    const choices = menu?.choiceSets[key].choices;

    console.log('choices : ', choices);

    const choiceIds: any = choices?.map(choice => choice.id);

    console.log('choiceIds : ', choiceIds);

    allChoiceIds = [...allChoiceIds, ...choiceIds];
  });

  console.log('allChoiceIds : ', allChoiceIds);

  const unavailableChoices = userPurchaseChoicesetValues.filter(
    value => !allChoiceIds.includes(value),
  );

  console.log('unavailableChoices : ', unavailableChoices);

  if (unavailableChoices.length) {
    let choiceSelectIdsCausingError: any = [];

    unavailableChoices.forEach(choice => {
      if (choiceValueToKeyMapper[choice]) {
        choiceSelectIdsCausingError.push(choiceValueToKeyMapper[choice]);
      }
    });

    console.log('choiceSelectIdsCausingError : ', choiceSelectIdsCausingError);

    choiceSelectIdsCausingError = [...new Set(choiceSelectIdsCausingError)];

    console.log(
      'after SET choiceSelectIdsCausingError : ',
      choiceSelectIdsCausingError,
    );

    const unavailableItems = newPurchases.filter(purchase =>
      choiceSelectIdsCausingError.some((id: any) =>
        Object.keys(purchase.choiceSelections!).includes(id),
      ),
    );

    console.log('unavailableItems from CHOICE : ', unavailableItems);

    const unavailableItemIds = unavailableItems.map(
      purchase => purchase.item.id,
    );

    console.log('unavailableItemIds from CHOICE : ', unavailableItemIds);

    console.log('NOT A GOOD CHOICE 😏');
    return { error: true, unavailableItems: unavailableItemIds };
  } else {
    console.log('VERY VERY GOOD CHOICE 😋');
    return { error: false };
  }
};

const isSizeChangeErrorDetected = (
  newPurchases: PurchaseWithTotals[],
  menu: Menu | undefined,
) => {
  const unavailableItemsForSize: any = [];

  console.log('------------😎😎😎------------');
  console.log('menu : ', menu);

  newPurchases.forEach(purchase => {
    console.log('purchase : ', purchase);
    const id = purchase.item.id;
    console.log('id : ', id);
    const item = menu?.items[id];
    console.log('item : ', item);
    const sizesIds = item?.sizes?.map((size: any) => size.id);
    console.log('sizesIds : ', sizesIds);
    const error =
      sizesIds && sizesIds.length && !sizesIds?.includes(purchase.sizeId);

    if (error) {
      unavailableItemsForSize.push(id);
    }
  });

  console.log('------------😎😎😎------------');

  if (unavailableItemsForSize.length) {
    return { error: true, unavailableItems: unavailableItemsForSize };
  }
  return { error: false };
};

const getBufferWarningsForOffer = createSelector(
  [
    getSelectedOffer,
    $getSelectedOffer,
    getSelectedOfferHasEffect,
    $getSelectedOfferHasEffect,
  ],
  (
    prevOffer,
    newOffer,
    prevOfferhasEffect,
    newOfferHasEffect,
  ): BufferWarning[] => {
    if (prevOffer && !newOffer) {
      return [
        {
          type: BUFFER_WARNING_TYPE.OFFER_UNAVAILABLE,
          message: prevOffer.name,
        },
      ];
    }

    if (prevOffer && prevOfferhasEffect && !newOfferHasEffect) {
      return [
        {
          type: BUFFER_WARNING_TYPE.OFFER_INEFFECTIVE,
          message: prevOffer.name,
        },
      ];
    }

    return [];
  },
);

// deciding not to warn about price changes. they can see them in the cart screen
// more important to warn about availability
const getBufferWarningsForCart = createSelector(
  [
    getBuffer,
    getAllLocations,
    getStagedPurchases,
    $getStagedPurchases,
    getMenu,
  ],
  (
    buffer,
    allLocations,
    prevPurchases,
    newPurchases,
    menu,
  ): BufferWarning[] => {
    const warnings: BufferWarning[] = [];

    console.log('buffer : ', buffer);

    console.log('check : ', menu?.items['8295']);
    console.log('check 2.0 : ', buffer?.menu?.items['8295']);

    // Getting the new location id from buffer
    const newLocationId = buffer.locationId;

    // Handling location errors
    if (!allLocations || !newLocationId) {
      throw new Error('Cannot find locations');
    }

    // Getting the new location from all locations
    const newLocation = allLocations[newLocationId];

    console.log('newLocation : ', newLocation);

    // New location brands
    const newLocationBrandIds = newLocation?.brandIds;

    // console.log('newLocationBrandIds : ', newLocationBrandIds);

    console.log('rootMenuNode : ', menu?.rootMenuNode);

    // Getting all the data that includes location brand ids
    const filteredData = menu?.rootMenuNode?.subMenus?.filter(menu =>
      menu.subMenus.some(subMenu =>
        newLocationBrandIds?.includes(subMenu.brandId!),
      ),
    );

    // console.log('filteredData : ', filteredData);

    // Getting all menu items for this location store
    const allMenuItems: any = filteredData?.map(data =>
      data.subMenus.flatMap(menu => menu.items),
    );

    // console.log('allMenuItems : ', allMenuItems);

    // Combining them in one array
    const allMenuItemsCombined: any =
      allMenuItems && [].concat(...allMenuItems);

    // console.log('allItemsCombined : ', allMenuItemsCombined);

    console.log('prevPurchases : ', prevPurchases);
    console.log('newPurchases : ', newPurchases);

    // Storing all the item ids of the new purchases
    const userNewPurchasesIds = newPurchases.map(entry => entry.item.id);

    console.log('userNewPurchasesIds : ', userNewPurchasesIds);

    const unavailableItems = userNewPurchasesIds?.filter(
      id => !allMenuItemsCombined?.includes(id),
    );

    console.log('unavailableItems : ', unavailableItems);

    if (unavailableItems.length) {
      console.log('Sorry! my friend I have to remove items from your cart');

      // Pushing the warnings
      warnings.push({
        type: BUFFER_WARNING_TYPE.ITEM_UNAVAILABLE,
        message: '',
        unavailableItems,
      });
    }

    const choiceSetError = isChoiceSetChangeErrorDetected(newPurchases, menu);

    if (
      choiceSetError.error &&
      choiceSetError.unavailableItems &&
      choiceSetError.unavailableItems.length
    ) {
      // Pushing the warnings
      warnings.push({
        type: BUFFER_WARNING_TYPE.ITEM_CHOICES_MODIFIED,
        message: '',
        unavailableItems: choiceSetError.unavailableItems,
      });
    }

    const sizeChangeError = isSizeChangeErrorDetected(newPurchases, menu);

    if (
      sizeChangeError.error &&
      sizeChangeError.unavailableItems &&
      sizeChangeError.unavailableItems.length
    ) {
      // Pushing the warnings
      warnings.push({
        type: BUFFER_WARNING_TYPE.ITEM_SIZE_MODIFIED,
        message: '',
        unavailableItems: sizeChangeError.unavailableItems,
      });
    }

    // TODO: check if this matches OLO3
    // I wonder in the new menu structure we may have to go through a different list of items
    // Could it be that the menu and choiceitems varies? not sure how we map the stagedPuchase from Menu
    prevPurchases.forEach(purchase => {
      const newVersion = newPurchases.find(
        newPurchase => newPurchase.id === purchase.id,
      );

      // were not able to translate purchase to an acceptable available item
      if (!newVersion) {
        warnings.push({
          type: BUFFER_WARNING_TYPE.ITEM_UNAVAILABLE,
          message: purchase?.item?.name,
        });

        return;
      }

      // This could be more involved/sensitive (like checking if min/max has changed)
      const anyChoiceModified =
        (purchase.choicesWithQuantity || []).length !==
        (newVersion.choicesWithQuantity || []).length;

      if (anyChoiceModified) {
        warnings.push({
          type: BUFFER_WARNING_TYPE.ITEM_CHOICES_MODIFIED,
          message: purchase?.item?.name,
        });
      }

      // the change has broken a purchase
      if (purchase.valid && !newVersion.valid) {
        warnings.push({
          type: BUFFER_WARNING_TYPE.ITEM_INVALID,
          message: purchase.item.name,
        });
      }
    });

    return warnings;
  },
);

export default createSelector(
  [getBufferReadyToApply, getBufferWarningsForCart, getBufferWarningsForOffer],
  (
    bufferReadyToApply,
    cartWarnings,
    offerWarnings,
  ): undefined | BufferWarning[] => {
    if (!bufferReadyToApply) {
      return undefined;
    }

    return [...cartWarnings, ...offerWarnings];
  },
);
