import React from 'react';
import { useTranslation } from 'react-i18next';
import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Text from './Text';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import Energy from './Energy';
import AllergenTagList from './AllergenTagList';

const { getStockBalanceDataByItem, getOpenPurchase } = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const MenuItemDescriptions = ({
  item,
  availabilityTimeString = '',
}: {
  item: Item;
  availabilityTimeString: string;
}) => {
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  const hideMenuItemSizeDescription = useAppSelector(
    state => state.config.hideMenuItemSizeDescription,
  );
  const stockBalanceData = useAppSelector(
    getStockBalanceDataByItem(item, useAppSelector(getOpenPurchase) as PurchaseWithTotals),
  );

  const checkIfDisabled =
    stockBalanceData && (stockBalanceData.soldOut || stockBalanceData.soldOutByCartSelections);

  const multiSized = !!item.sizes.length;
  const hasRequiredPaidChoices = item.choiceSets.some(
    choiceSet => choiceSet.min && choiceSet.choices.some(choice => choice.baseMoneyPrice),
  );
  const priceShowing = multiSized ? item.minSizeBaseMoneyPrice : item.baseMoneyPrice;
  const price = useFormattedCurrency({ cents: priceShowing });
  const priceString = priceShowing
    ? `${multiSized || hasRequiredPaidChoices ? 'From ' : ''}${price}`
    : null;

  const kilojoulesShowing = multiSized ? item.minSizeKilojoules : item.kilojoules;

  return (
    <div
      style={combineStyles({ height: '100%' }, Boolean(checkIfDisabled) && styles.menuItemOpacity)}
    >
      <div style={{ marginTop: deviceTypeMobile ? 0 : 10 }}>
        <Text themeKey="menuItemName" value={item.name} style={styles.name} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        {!!priceShowing && (
          <Text
            themeKey="menuItemPrice"
            // style={combineStyles(Boolean(checkIfDisabled) && styles.menuItemOpacity)}
          >
            {priceString}
          </Text>
        )}

        {!!kilojoulesShowing && <Energy kilojouleCount={kilojoulesShowing} />}
      </div>

      <div>
        {Boolean(item.description) && (
          <Text
            themeKey="menuItemDescription"
            value={item.description}
            style={styles.description}
          />
        )}
      </div>

      <div>
        {stockBalanceData && stockBalanceData.stockBalanceThreshold && (
          <Text themeKey={stockBalanceData.stockBalanceThreshold} style={styles.stockBalanceText}>
            {t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
          </Text>
        )}
      </div>
      {availabilityTimeString && (
        <div>
          <Text themeKey="menuItemDescription" style={styles.itemAvailable}>
            {availabilityTimeString}
          </Text>
        </div>
      )}

      <AllergenTagList
        item={item.sizes.find(s => s.id === item.default_size) ?? item}
        includeTempTags={false}
      />
    </div>
  );
};

export default MenuItemDescriptions;

const styles: Styles = {
  itemAvailable: {
    fontSize: 12,
  },
  menuItemOpacity: {
    opacity: 0.5,
  },
  description: {
    marginTop: 8,
    marginBottom: 8,
  },
  energySuffix: {
    marginLeft: 1,
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100%',
    alignItems: 'flex-start',
    overflowWrap: 'anywhere',
  },
  stockBalanceText: {
    marginTop: 18,
  },
};
