import React, { useState, useEffect, useRef, useContext } from 'react';
import lodash, { mixin, xor } from 'lodash';
import { useTranslation } from 'react-i18next';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { RiArrowDownSLine } from 'react-icons/ri';

import {
  OrderingSelectors,
  OrderingOperations,
  OrderingConstants,
  OrderingHooks,
} from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import RedcatImage from '../components/RedcatImage';
import QuantityControl from '../components/QuantityControl';
import StandardButton from '../components/StandardButton';
import ChoiceSet from '../components/ChoiceSet';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { addToCart } from '../utils/analytics';
import NestedChoiceSets from '../components/NestedChoiceSets';
import {
  NestedChoiceSetsContext,
  INestedChoiceSetsContext,
  createPurchaseFromStages,
  NestedIngredientsStages,
} from '../components/NestedChoiceSetsContext';
import NestedChoiceSetBanner from '../components/NestedChoiceSetBanner';
import MessageModal from './MessageModal';
import ItemTopHeader from '../components/ItemTopHeader';
import getNestedTags from '../utils/getNestedTags';
import { DEVICE_TYPES } from '../constants';
import { computeTagsForItem } from '../libs/polygon-ordering/src/utils/ordering/computeTagsForItem';
import { UPSELL_MODAL } from './Upsells';
import AddToCartButton from '../components/AddToCartButton';
import { getFilteredRootCategory } from '../libs/polygon-ordering/src/selectors';

const {
  getOpenPurchase,
  getOpenPurchasePreviouslyStaged,
  getHideNegativeIngredients,
  getOpenPurchaseSizes,
  getAllergenDietaryTags,
  getAllergenDietaryDetails,
  getNestedItemStockBalancesData,
  getUpsellsByItem,
  getEnabledUpsellByItem,
  getUpsellDefaultTitle,
  getItemAvailability,
  getMenu,
  getBrands,
} = OrderingSelectors;
const { OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT } = OrderingConstants;
const { useFormattedCurrency } = OrderingHooks;

const {
  stageOpenPurchase,
  changeOpenPurchaseItem,
  adjustOpenPurchaseChoice,
  setOpenPurchaseLowestBalance,
  adjustOpenPurchaseQuantity,
} = OrderingOperations;

export const PURCHASE_EDITOR_MODAL_ID = 'PURCHASE_EDITOR_MODAL_ID';

const BOTTOM_PADDING = 170;

const PurchaseEditorModal = () => {
  const openPurchase = useAppSelector(getOpenPurchase);
  const sizes = useAppSelector(getOpenPurchaseSizes);

  const p = useAppSelector(getThemeLookup);
  const previouslyStaged = useAppSelector(getOpenPurchasePreviouslyStaged);
  const [pages, setPages] = useState<(ValidatedChoiceSet | ValidatedNestedChoiceSet)[][]>([]);
  const [activatePage, setActivatePage] = useState(0);

  const hideNegativeIngredients = useAppSelector(getHideNegativeIngredients);
  const collapsableChoiceSetsEnabled = useAppSelector(
    state => state.config.collapsableChoiceSetsEnabled,
  );
  const collapseOptionalChoiceSetsByDefault = useAppSelector(
    state => state.config.collapseOptionalChoiceSetsByDefault,
  );
  const brands = useAppSelector(getBrands);
  const rootCategory = useAppSelector(getFilteredRootCategory);

  const [state, setState] = useState<{
    showIndicator: boolean;
    collapsedChoiceSets: { [key: string]: boolean };
  }>({
    showIndicator: false,
    collapsedChoiceSets: {},
  });
  const psbContainerRef = useRef<any>(null);
  const { t } = useTranslation();
  const {
    purchased,
    setPurchased,
    stages,
    setStages,
    setShowChoices,
    showNestedItem,
    setShowNestedItem,
    nestedItemInValid,
    nestedChoiceSetsTotal,
    isSelectionValid,
  } = useContext(NestedChoiceSetsContext) as INestedChoiceSetsContext;

  const steps = Object.keys(stages || {}).length;
  const [messageBox, setMessageBox] = useState(false);

  const menu = useAppSelector(getMenu);
  const allChoiceSets = menu?.choiceSets as ChoiceSets | NestedChoiceSets;

  const enableAddItemButton = (
    stages: NestedIngredientsStages,
    choiceSets: SDict<ChoiceSet | NestedChoiceSet>,
  ): boolean => {
    return (
      (steps === 3 &&
        choiceSets &&
        'nestedIngredients' in choiceSets[(stages || {})[0] as string]) ||
      (steps === 2 &&
        choiceSets &&
        !('nestedIngredients' in choiceSets[(stages || {})[0] as string]))
    );
  };

  useEffect(() => {
    // assess();
    dispatch(setOpenPurchaseLowestBalance(OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT));
  });

  const deviceType = useAppSelector(state => state.deviceType);

  useEffect(() => {
    const choiceSets = lodash.get(openPurchase, 'choiceSets', []);
    const collapsedChoiceSets: { [key: string]: boolean } = {};

    choiceSets.forEach((choiceSet: ValidatedChoiceSet) => {
      const isRadioButton = choiceSet.min === 1 && choiceSet.max === 1;
      if (!choiceSet.min || (choiceSet.valid && !isRadioButton)) {
        collapsedChoiceSets[choiceSet.id] = collapsableChoiceSetsEnabled
          ? collapseOptionalChoiceSetsByDefault
          : false;
      }
    });

    setState({ ...state, collapsedChoiceSets });
  }, [collapsableChoiceSetsEnabled, collapseOptionalChoiceSetsByDefault, openPurchase?.item.id]);

  const dispatch = useAppDispatch();
  if (!openPurchase) {
    dispatch(setCurrentModal(null));
    return null;
  }

  const {
    item,
    choiceSets = [],
    moneyPrice,
    valid,
    quantity,
    sizeId,
    choicesWithQuantity,
    brandId,
  } = openPurchase;
  const category = useAppSelector(state => state.modalParams?.category);

  const brand = brands.find(brand => brand.id === brandId)?.name || null;
  const [daypartAvailability, availabilityString] = useAppSelector(
    getItemAvailability(item, category?.id || '', true),
  );
  const availabilityTimeString =
    !daypartAvailability && availabilityString
      ? t('itemAvailable', { itemAvailable: availabilityString })
      : '';
  const sizeItem = (!!item.sizes.length && item.sizes.find(s => s.id === sizeId)) || item;
  const isComposite = !!item.sizes.length;

  const upsells = useAppSelector(getUpsellsByItem(item));
  const enableUpsell = useAppSelector(getEnabledUpsellByItem);
  const upsellTitle = item.upsell_title ? item.upsell_title : useAppSelector(getUpsellDefaultTitle);

  const mixinRegex = /mix-ins/i;

  const mixinChoicesets = choiceSets?.filter(c => mixinRegex.test(c.name));

  const morePagesExist = activatePage + 1 < pages.length;

  const handleBackButtonClick = () => {
    const steps = Object.keys(stages || {}).length;

    if (stages) {
      const newStages = { ...stages };
      delete newStages[steps - 1];
      setStages({ ...newStages });
    }
    if (steps === 3) {
      setShowChoices(false);
      setShowNestedItem(true);
    }

    if (steps === 2 || steps === 1) {
      setShowNestedItem(false);
      setShowChoices(false);
      setStages(undefined);
    }
  };

  const handleAddToItem = () => {
    if (stages) {
      const purchase = createPurchaseFromStages(stages);

      setPurchased({ ...purchased, ...purchase });
    }

    setShowChoices(false);
    setShowNestedItem(false);
    setStages(undefined);
  };

  const toggleCollapsed = (id: string) => {
    const { collapsedChoiceSets } = state;

    setState({
      ...state,
      collapsedChoiceSets: {
        ...collapsedChoiceSets,
        [id]: !collapsedChoiceSets[id],
      },
    });
  };

  const handleAddToCart = () => {
    if (enableUpsell && !previouslyStaged)
      dispatch(
        setCurrentModal({
          modalId: UPSELL_MODAL,
          params: {
            items: upsells,
            title: upsellTitle || t('title.modal.upsellTitle'),
          },
        }),
      );
    // analytics
    // TODO: correct price here?
    addToCart(item, moneyPrice, quantity, choicesWithQuantity, brand, category?.name || null);
    // actual cart stuff
    dispatch(
      stageOpenPurchase(
        !!choiceSets.filter(c => 'nestedIngredients' in c && c.displayType !== 'checkbox').length
          ? { choiceSelections: purchased, enableUpsell }
          : { enableUpsell },
      ),
    );
  };
  const nested = !!choiceSets.filter(c => 'nestedIngredients' in c && c.displayType !== 'checkbox')
    .length;

  const [, minBalanceStaged] = useAppSelector(
    getNestedItemStockBalancesData(openPurchase, purchased),
  );

  const validateCart = () => {
    return nested ? nestedItemInValid : !valid;
    // ||
    // (minBalanceStaged !== undefined && quantity > minBalanceStaged)
  };

  const { showIndicator, collapsedChoiceSets } = state;

  const SIZE_CHOICE_SET = {
    id: 'SIZE_CHOICE_SET',
    name: item.sizes_label || t('title.sizeChoice'),
    free: 0,
    max: 1,
    min: 1,
    valid: true,
    quantity: 1,
    choices: (sizes || []).map((size: { [key: string]: any }) => ({
      ...size,
      name: size.name,
      quantity: openPurchase.sizeId === size.id ? 1 : 0,
      baseMoneyPrice: size.baseMoneyPrice - item.minSizeBaseMoneyPrice,
    })),
  };

  const noNestedChoiceSets = !(
    choiceSets.filter(c => 'nestedIngredients' in c && c.displayType !== 'checkbox').length > 0
  );

  const [modified, setModified] = useState<boolean>(false);

  const handleClose = () => {
    // only show the close warning if the user changed anything
    if (!modified) {
      // dispatch(resetTempTags());
      dispatch(setCurrentModal(null));
    } else {
      setMessageBox(true);
    }
  };

  const isMobileDevice = deviceType == DEVICE_TYPES.MOBILE;

  const baseTextSize = { fontSize: isMobileDevice ? 14 : 16 };

  const baseContainerStyle = combineStyles(styles.container, { padding: isMobileDevice ? 10 : 30 });

  const normalChoiceSetContainerStyle = combineStyles(
    baseContainerStyle,
    // p('defaultBorder', ['borderTop']),
    isMobileDevice ? { paddingTop: 20, paddingBottom: 20 } : {},
  );

  const topSectionContainerStyle = combineStyles(
    baseContainerStyle,
    isMobileDevice ? { paddingBottom: 20 } : {},
  );

  const addToItemEnabled = enableAddItemButton(stages, allChoiceSets);
  const isRootItem = steps === 0;

  const [subItemPrice, setSubItemPrice] = useState<number>();
  const price = useFormattedCurrency({ cents: subItemPrice });

  // the following hook code may not necessarily need to use hooks, not sure

  // use this state variable when we add actual PLU images
  // TODO: make this not a placeholder, or fix logic to be  more elegant, I just added these to make my mobile modal fixes more maintainable
  const [imageShowing, setImageShowing] = useState<string | undefined>();
  // set this once to the default image of the composite item object
  // when we change items, don't change the image from this (unless going to a nested item)
  const [compositeImage, setCompositeImage] = useState<string | undefined>();

  //store the scroll position for nested choicesets
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    if (psbContainerRef.current) {
      if (!showNestedItem) psbContainerRef.current.scrollTo({ top: scrollTop });
      else {
        psbContainerRef.current.scrollTo({ top: 0 });
      }
    }
  }, [showNestedItem]);

  // NOTE: past Heath's code here sucks, the useeffects have the same dependencies. TODO: at least combine these useeffects when you refactor this image logic
  // if we are the root item then set the image to the root item image (or composite if set)
  useEffect(() => {
    if (isRootItem) {
      setImageShowing(compositeImage ?? sizeItem.images.default);
    }
  }, [item, isRootItem, compositeImage]);
  useEffect(() => {
    // composite image is only set once
    if (isRootItem && isComposite && !compositeImage) {
      // item is assumed to be root composite item in this case (as the composite image has not already been set)
      setCompositeImage(item.images.default);
    }
  }, [item, compositeImage, isRootItem]);

  useEffect(() => {
    const { choiceSets } = openPurchase;
    if (isComposite && mixinChoicesets.length > 0) {
      setPages([choiceSets.filter(c => !mixinRegex.test(c.name)), mixinChoicesets]);
    } else {
      setPages([choiceSets]);
    }
  }, [openPurchase]);

  const tagDetails = useAppSelector(getAllergenDietaryDetails);
  const tags = computeTagsForItem(
    sizeItem,
    (nested ? purchased : openPurchase.choiceSelections) as NestedChoiceSelections,
    tagDetails,
  );

  const handleIncreaseQty = () => {
    // if (minNestedStockBalanceStaged && quantity >= minNestedStockBalanceStaged)
    //   enqueueWarningSnackbar(t('stagedChoiceSetItemQuantityWarningMessage'));
    setModified(true);
    dispatch(adjustOpenPurchaseQuantity(1));
  };

  // TODO: refactor this image logic
  const imagePath = imageShowing ?? item.images.default;

  const enableBackButtonForMixins = isComposite && !morePagesExist && pages.length > 1;

  return (
    <>
      <Modal
        // TODO: fix this to be correct size from wireframes? (wireframe scale different to real screens?)
        containerStyle={styles.modalContainer}
        // desktopMaxWidth={1200}
        desktopMinWidth={600}
        desktopMaxHeight={600}
        desktopMinHeight={600}
        backgroundClick={handleClose}
        handleClose={handleClose}
        handleBack={
          enableBackButtonForMixins
            ? () => setActivatePage(p => p - 1)
            : isRootItem
            ? handleClose
            : handleBackButtonClick
        }
        mobileContainerStyle={styles.mobileContainer}
        enableBackButton={enableBackButtonForMixins ? true : !isRootItem || isMobileDevice}
        tallestPossible={isMobileDevice}
      >
        {/* image on desktop */}
        <div>
          {!isMobileDevice && (
            <RedcatImage
              alt={item.name}
              imagePath={imagePath}
              containerStyle={combineStyles({
                position: 'relative',
                maxWidth: 600,
                display: 'flex',
                overflow: 'hidden',
              })}
              imageStyle={p('purchaseEditorImage', ['objectFit'])}
            />
          )}
        </div>

        {/* content container */}
        <div
          style={combineStyles(baseTextSize, {
            flex: 1,
            position: 'relative',
            maxWidth: 600,

            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',

            // More stuff for parallax
            zIndex: 0,
            // transform: 'translateZ(0)',
          })}
        >
          <div
            ref={psbContainerRef}
            style={combineStyles({
              display: 'flex',
              flexDirection: 'column',
              overflow: 'scroll',

              perspective: '2px',
              WebkitPerspective: '2px',
              perspectiveOrigin: 'top',
              transformStyle: 'preserve-3d',
              WebkitTransformStyle: 'preserve-3d',

              flex: 1,
            })}
          >
            {/* image for mobile */}
            {isMobileDevice && (
              <RedcatImage
                alt={item.name}
                imagePath={imagePath}
                containerStyle={combineStyles({
                  // display: 'flex',
                  // overflow: 'hidden',
                  position: 'relative',
                  width: '100%',
                  maxHeight: '50%', // TODO fix this?
                  transformStyle: 'preserve-3d',
                  WebkitTransformStyle: 'preserve-3d',
                  transform: `translateZ(-2px) scale(2)${
                    // this is for the users who are viewing on a touchscreen-less display, as the scroll shifts this.
                    // window.matchMedia('(pointer: fine)').matches ? ' translateX(-4px)' : ''
                    ''
                  }
                  `,
                  transformOrigin: 'top',
                  zIndex: -1,
                })}
                imageStyle={p('purchaseEditorImage', ['objectFit'])}
              />
            )}
            <div
              style={combineStyles(
                {
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                },
                p('screen', ['backgroundColor']),
              )}
            >
              {/* top section */}
              {isRootItem && (
                <div style={topSectionContainerStyle}>
                  <div style={{ padding: 20 }}>
                    <ItemTopHeader
                      item={item}
                      tags={tags}
                      availabilityTimeString={availabilityTimeString}
                    />
                  </div>
                </div>
              )}
              {/* choice set sections */}
              {/* hacky seperator between header and choices */}
              {(noNestedChoiceSets || isComposite) && (
                <div style={p('defaultBorder', ['borderTop'])} />
              )}
              {/* non-sizing/composite normal choices */}
              {noNestedChoiceSets ? (
                <div style={normalChoiceSetContainerStyle}>
                  {/* composite variety choiceset */}
                  {isComposite && activatePage === 0 && (
                    <>
                      <NestedChoiceSetBanner
                        choiceSet={SIZE_CHOICE_SET as ValidatedChoiceSet}
                        collapsed={collapsedChoiceSets[SIZE_CHOICE_SET.id]}
                        toggleCollapsed={toggleCollapsed}
                      />

                      {!collapsedChoiceSets[SIZE_CHOICE_SET.id] && (
                        <ChoiceSet
                          choiceSet={SIZE_CHOICE_SET as ValidatedChoiceSet}
                          adjustOpenPurchaseChoice={({ targetChoiceId }) => {
                            setModified(true);
                            dispatch(
                              changeOpenPurchaseItem({
                                sizeId: targetChoiceId,
                                choiceSelections: openPurchase.choiceSelections,
                              }),
                            );
                          }}
                          hideNegativeIngredients={hideNegativeIngredients}
                        />
                      )}
                    </>
                  )}
                  {pages[activatePage]?.map((choiceSet, index) => {
                    const collapsed = Boolean(collapsedChoiceSets[choiceSet.id]);

                    const isRadioButton = choiceSet.min === 1 && choiceSet.max === 1;

                    const quantityWarning =
                      (choiceSet.min
                        ? choiceSet.max
                          ? choiceSet.min === choiceSet.max
                            ? t('choiceSet.quantityHint.constant', { quantity: choiceSet.min })
                            : t('choiceSet.quantityHint.range', {
                                min: choiceSet.min,
                                max: choiceSet.max,
                              })
                          : ''
                        : t('choiceSet.quantityHint.maximum', { max: choiceSet.max })) +
                      (choiceSet.free &&
                      (!choiceSet.max || !choiceSet.free || choiceSet.max > choiceSet.free)
                        ? ' ' + t('choiceSet.quantityHint.freeSuffix', { free: choiceSet.free })
                        : '');

                    return (
                      <div
                        style={styles.normalChoiceSet}
                        key={`${choiceSet.id}_${index.toString()}`}
                      >
                        {choiceSet.displayType !== 'checkbox' && (
                          <div>
                            <NestedChoiceSetBanner
                              key={`${choiceSet.id}_banner`}
                              choiceSet={choiceSet}
                              collapsed={collapsed}
                              toggleCollapsed={toggleCollapsed}
                              hideCaret={mixinRegex.test(choiceSet.name)}
                            />
                            {!!quantityWarning && !collapsed && !isRadioButton && (
                              <Text
                                themeKey="choiceSetQuantityHint"
                                style={{
                                  paddingLeft: 20,
                                  fontSize: isMobileDevice ? 12 : 14,
                                }}
                                value={quantityWarning}
                              />
                            )}
                          </div>
                        )}
                        {(choiceSet.displayType === 'checkbox' || !collapsed) && (
                          <ChoiceSet
                            key={`${choiceSet.id}_display`}
                            choiceSet={choiceSet}
                            adjustOpenPurchaseChoice={targetChoiceSet => {
                              setModified(true);
                              dispatch(adjustOpenPurchaseChoice(targetChoiceSet));
                            }}
                            hideNegativeIngredients={hideNegativeIngredients}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                // nested items
                <NestedChoiceSets
                  rootItem={item}
                  choiceSets={choiceSets}
                  adjustOpenPurchaseChoice={adjustOpenPurchaseChoice}
                  hideNegativeIngredients={hideNegativeIngredients}
                  setImage={setImageShowing}
                  setItemPrice={setSubItemPrice}
                  onModify={() => setModified(true)}
                  onClick={() => {
                    setScrollTop(psbContainerRef.current.scrollTop);
                  }}
                />
              )}
              {showIndicator && (
                <TouchableOpacity
                  style={combineStyles(
                    styles.indicator,
                    p('scrollForMoreIndicator', CONTAINER_PROPERTIES),
                  )}
                  onClick={() => psbContainerRef.current!.scrollBy({ top: 200 })}
                  ariaLabel="scroll to see more"
                >
                  <RiArrowDownSLine style={p('scrollForMoreIndicator', ['color'])} />
                </TouchableOpacity>
              )}
            </div>
          </div>

          {item.alcoholic && (
            <div style={{ textAlign: 'center', padding: 5 }}>
              <Text style={{ fontSize: 12 }}>{t('itemHasAlcohol')}</Text>
            </div>
          )}

          {(isRootItem || addToItemEnabled) && (
            <div
              style={combineStyles(
                p('purchaseEditorBottomSection', ['backgroundColor']),
                isMobileDevice && p('purchaseEditorBottomSectionMobileView', ['backgroundColor']),
                styles.bottomSection,
                isMobileDevice && styles.mobileBottomSection,
                p('defaultBorder', ['borderTop']),
              )}
            >
              {isRootItem && (
                <>
                  <QuantityControl
                    quantity={quantity}
                    maxQuantity={minBalanceStaged}
                    increaseQty={handleIncreaseQty}
                    decreaseQty={() => {
                      setModified(true);
                      dispatch(adjustOpenPurchaseQuantity(-1));
                    }}
                    buttonSize={isMobileDevice ? 30 : 35}
                    increaseEnabled={!validateCart() && daypartAvailability}
                    isMobileDevice={isMobileDevice}
                  />

                  <AddToCartButton
                    onClick={morePagesExist ? () => setActivatePage(p => p + 1) : handleAddToCart}
                    label={
                      morePagesExist
                        ? t('button.purchaseEditor.next')
                        : previouslyStaged
                        ? t('button.purchaseEditor.modified')
                        : t('button.purchaseEditor.new')
                    }
                    value={
                      noNestedChoiceSets
                        ? moneyPrice
                        : quantity * (item.baseMoneyPrice + nestedChoiceSetsTotal)
                    }
                    valid={valid && daypartAvailability}
                    showLabelOnly={morePagesExist}
                  />
                </>
              )}
              {addToItemEnabled && (
                <StandardButton
                  themeKey="modalProceedButton"
                  label={`${t('button.addToItem')} ${subItemPrice ? ` - ${price}` : ``}`}
                  onClick={handleAddToItem}
                  style={combineStyles(styles.button, { marginRight: '10%', marginLeft: '10%' })}
                  disabled={
                    !isSelectionValid ||
                    (minBalanceStaged !== undefined && quantity > minBalanceStaged) ||
                    minBalanceStaged === 0
                  }
                  disabledStyle={styles.disabledButton}
                />
              )}
            </div>
          )}
        </div>
      </Modal>
      {messageBox && (
        <MessageModal
          title="This item has not been added to the cart"
          message="Are you sure you want to continue to the menu?"
          titleStyle={{ fontSize: 20 }}
          desktopMaxWidth={450}
          desktopMinWidth={450}
          handleClose={() => {
            setMessageBox(false);
          }}
        />
      )}
    </>
  );
};

const styles: Styles = {
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    //minHeight: '100%',
    // maxHeight: '100%',

    overflow: 'scroll',
    // For paralax image header
    borderRadius: 0,
    padding: 0,
    gap: 0,
  },
  modalContainer: {
    flex: 1,
    // auto sized grid so that the component works correctly if the image is missing
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, 1fr)',

    overflow: 'hidden',
    fontSize: 10,
    borderRadius: 0,
    padding: 0,
    gap: 0,
  },

  scrollable: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    overflow: 'scroll',
  },

  scrollableExtraPadding: {
    paddingBottom: BOTTOM_PADDING,
  },
  price: {
    fontSize: 16,
  },

  button: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    flexGrow: 1,
    padding: 10,
  },

  indicator: {
    position: 'sticky',
    left: 20,
    bottom: 10,
    height: 50,
    width: 50,
    backgroundColor: 'green',
    zIndex: 900,
    borderRadius: '50%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 3,
    fontSize: 40,
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
    padding: '25px 30px',
    justifyContent: 'space-between',
    height: 100,
  },
  mobileBottomSection: {
    padding: 30,
    paddingTop: 20,
  },
  container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    //minHeight: '100%',
  },
  normalChoiceSet: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  backButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    padding: '1em',
    zIndex: 900,
  },
};

export default PurchaseEditorModal;
