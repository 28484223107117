import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { reserveOrCheckBuffer } from './reserveOrCheckBuffer';

import { clearBuffer, setBufferReadyToApply } from '../actions/buffer';
import { setBufferLocationId } from '../reducers/buffer/locationId';
import { setBufferSaleType } from '../reducers/buffer/saleType';
import { $getLocationId } from '../selectors/getLocationId';
import {
  getEnableDynamicPaymentGatewayConfig,
  getEnableMultipleDeliveryEstimate,
} from '../selectors/config';
import { SALE_TYPE } from '../constants/saleType';
import { FAILURE_REASON } from '../constants';
import { applyLocationEstimate } from './applyLocationEstimate';
import { getLocationDeliveryEstimates, getMember } from '../selectors';
import { fetchOffers } from './fetchOffers';
import { applyBuffer } from './applyBuffer';
import { fetchMenu } from './fetchMenu';
import { fetchPaymentGatewayConfig } from './fetchPaymentGatewayConfig';
import { fetchOrderingProviderDetails } from './fetchOrderingProviderDetails';
import { fetchDeliveryEstimate } from './fetchDeliveryEstimate';

export const updateKeyOrderProperty = createAsyncThunk(
  '$updateKeyOrderProperty',
  async (
    data: {
      locationId?: string;
      saleType?: SALE_TYPE;
      autoApply?: boolean;
      desiredDeliveryTime?: string;
      deliveryAddress?: string;
      updateDeliveryEstimate?: boolean;
      confirmLocationDeliveryEstimate?: boolean;
      forceASAPDeliveryEstimate?: boolean;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      locationId,
      saleType,
      autoApply,
      desiredDeliveryTime,
      deliveryAddress,
      updateDeliveryEstimate,
      confirmLocationDeliveryEstimate,
      forceASAPDeliveryEstimate,
    } = data;

    let controlsBuffer;
    let reason = FAILURE_REASON.UNKNOWN;
    let userReason: string | undefined;
    let systemReason: string | undefined;
    let receivedMultipleEstimates = false;

    try {
      const flowId = uuidv4();
      dispatch(reserveOrCheckBuffer({ flowId }));

      controlsBuffer = true;

      const initialBufferLocationId = $getLocationId(
        getState() as EntireFrontendState,
      );

      const enableMultipleDeliveryEstimate = getEnableMultipleDeliveryEstimate(
        getState() as EntireFrontendState,
      );

      const performDeliveryEstimate =
        !confirmLocationDeliveryEstimate &&
        (saleType === SALE_TYPE.DELIVERY || updateDeliveryEstimate);

      if (saleType != null) {
        dispatch(setBufferSaleType(saleType));
      }

      if (!performDeliveryEstimate && locationId != null) {
        dispatch(setBufferLocationId(locationId));
      }

      // performing delivery estimate

      if (performDeliveryEstimate) {
        if (locationId != null) {
          throw new Error('cannot provide locationId with delivery saleType');
        }

        let fetchParams: any = { forceASAPDeliveryEstimate };

        if (updateDeliveryEstimate) {
          if (enableMultipleDeliveryEstimate) {
            fetchParams = {
              ...fetchParams,
              locationId: initialBufferLocationId,
            };
          }
        } else {
          fetchParams = {
            ...fetchParams,
            deliveryAddress,
            desiredDeliveryTime,
            multiple: enableMultipleDeliveryEstimate,
          };
        }

        const { multiple } = await dispatch(
          fetchDeliveryEstimate(fetchParams),
        ).unwrap();

        if (multiple) {
          receivedMultipleEstimates = true;
        }
      }

      //confirm delivery estimate
      if (confirmLocationDeliveryEstimate) {
        if (locationId === null) {
          throw new Error('missing locationId');
        }

        const locationEstimates = getLocationDeliveryEstimates(
          getState() as EntireFrontendState,
        );
        const estimate = locationEstimates.find(
          estimate => estimate.locationId === locationId,
        );

        if (!estimate) {
          throw new Error('no matching estimate for locationId');
        }

        dispatch(applyLocationEstimate(estimate));
      }

      //handle multiple estimates
      if (!receivedMultipleEstimates) {
        const bufferLocationId = $getLocationId(
          getState() as EntireFrontendState,
        );

        if (bufferLocationId == null) {
          return;
        }

        const bufferLocationIdChanged =
          bufferLocationId !== initialBufferLocationId;

        if (bufferLocationIdChanged || !updateDeliveryEstimate) {
          //fetch menu
          await dispatch(fetchMenu()).unwrap();
        }

        if (bufferLocationIdChanged) {
          const memberPresent = Boolean(
            getMember(getState() as EntireFrontendState),
          );

          if (memberPresent) {
            //fetch offers
            await dispatch(fetchOffers({ bufferMode: true }));
          }
        }

        if (!performDeliveryEstimate || !updateDeliveryEstimate) {
          //fetch ordering provider details
          await dispatch(
            fetchOrderingProviderDetails({ bufferMode: true }),
          ).unwrap();
        }

        const enableDynamicPaymentGatewayConfig =
          getEnableDynamicPaymentGatewayConfig(
            getState() as EntireFrontendState,
          );

        if (enableDynamicPaymentGatewayConfig) {
          //fetch payment gateway config
          await dispatch(
            fetchPaymentGatewayConfig({
              bufferMode: true,
              locationId: bufferLocationId,
            }),
          ).unwrap();
        }
      }

      dispatch(setBufferReadyToApply(!receivedMultipleEstimates));

      if (
        !receivedMultipleEstimates &&
        (autoApply || updateDeliveryEstimate || confirmLocationDeliveryEstimate)
      ) {
        await dispatch(applyBuffer()).unwrap();
      }
    } catch (e) {
      if (controlsBuffer) {
        dispatch(clearBuffer({}));
      }

      console.warn('Update key order property failed', e, {
        reason,
        userReason,
        systemReason,
      });
      return rejectWithValue({
        e,
        reason: (e as any).reason ? (e as any).reason : reason,
        userReason: (e as any).userReason ? (e as any).userReason : userReason,
        systemReason: (e as any).systemReason
          ? (e as any).systemReason
          : systemReason,
      });
    }
  },
);
