import React, { useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import * as lodash from 'lodash';
import Divider from '@mui/material/Divider';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import MapView from './MapView';
import LocationListItem from './LocationListItem';
import getThemeLookup from '../selectors/getThemeLookup';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import HorizontalScrollableMenu from './HorizontalScrollableMenu';

const { setCurrentMenuBrandId: setCurrentMenuBrandIdRedux } = OrderingOperations;
const { getBrands, getCurrentMenuBrandId } = OrderingSelectors;

const Locations = ({ locations }: { locations: MapLocation[] }) => {
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  let brands = useAppSelector(getBrands);
  const currentBrandId = useAppSelector(getCurrentMenuBrandId);
  brands = lodash.orderBy(brands, ['sortOrder'], 'asc');
  brands.unshift({ id: '0', name: 'All' });
  const [currentMenuBrandId, setCurrentMenuBrandId] = useState<string | null>(
    currentBrandId || '0',
  );

  const [addressFilter, setAddressFilter] = useState('');
  const [filteredList, setFilteredList] = useState<MapLocation[] | null>(null);

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  useEffect(() => {
    setAddressFilter(values.formatted_address);
  }, [values.formatted_address]);

  useEffect(() => {
    filterList(addressFilter);
  }, [addressFilter, locations, currentMenuBrandId]);

  const filterList = (text: string) => {
    const newList = locations.filter(location => {
      const locationFilter = `${location.name} ${location.address.combinedLong}`
        .toLowerCase()
        .includes(text.toLowerCase());
      let brandFilter = true;
      if (currentMenuBrandId && currentMenuBrandId !== '0') {
        brandFilter = location.brandIds.includes(currentMenuBrandId);
      }
      return locationFilter && brandFilter;
    });
    setFilteredList(newList);
  };

  return (
    <div
      style={{
        padding: '0 0.6em',
      }}
    >
      {console.log({ locations, filteredList })}
      {filteredList &&
        (values.listView ? (
          <>
            {brands && (
              <HorizontalScrollableMenu
                items={brands}
                currentItemId={currentMenuBrandId}
                setCurrentItemId={id => {
                  setCurrentMenuBrandId(id);
                  dispatch(setCurrentMenuBrandIdRedux(id));
                  logEvent(EVENTS.CHANGE_MENU_BRAND_FILTER, { value: parseInt(id) });
                }}
                labelThemeKey={'menuBrandsBarLabel'}
                currentLabelThemeKey={'menuBrandsBarLabelCurrent'}
                currentIndicatorThemeKey={'menuBrandsBarCurrentIndicator'}
                iconSize={16}
                containerBackgroundColor={p('menuBrandsBar', ['backgroundColor']).backgroundColor}
                containerStyle={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  opacity: 1,
                  zIndex: 999,
                }}
                isBrand
              />
            )}
            {brands && <Divider variant="fullWidth" sx={{ opacity: 1 }} />}

            {filteredList?.map(location => (
              <LocationListItem
                activateBgColor={values.listView && values.locationId === location.id}
                location={location}
                key={location.id}
                onClick={() => {
                  setFieldValue('locationId', values.locationId === location.id ? '' : location.id);
                }}
              />
            ))}
          </>
        ) : (
          <MapView locations={filteredList} />
        ))}
    </div>
  );
};

export default Locations;
