import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { CiHeart } from 'react-icons/ci';
import { OrderingSelectors, OrderingOperations, OrderingHooks } from 'polygon-ordering';
import ScreenHero from './ScreenHero';
import ScreenFloating from './ScreenFloating';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';
import combineStyles from '../utils/combineStyles';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import NewOrderButton from './NewOrderButton';
import Text from '../components/Text';
import StandardButton from './StandardButton';
import moment from 'moment';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import TouchableOpacity from './TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { FAVOURITE_NAME_MODAL } from '../modals/FavouriteNameModal';
import { setOrderDate } from '../slices/orderDate';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import OrderDetailsSubPayments from './OrderDetailsSubPayments';
import OrderDetailsPurchases from './OrderDetailsPurchases';
import toDollarString from '../utils/toDollarString';
import { setReorderableItems } from '../slices/reorderableItems';

const {
  getSaleDetails,
  getLocation,
  getSelectedPaymentMethods,
  getStagedPurchases,
  getStagedPurchasesAsShadowItems,
  getOrderTotals,
  getSaleType,
  getSurcharges,
  getMember,
} = OrderingSelectors;
const { fetchInvoice, startNewOrder, setShadowCart } = OrderingOperations;
const { useFormattedCurrency } = OrderingHooks;

interface IProps {
  setViewOrderDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Line {
  title?: string;
  content?: string;
}

const Line: React.FC<Line> = ({ title, content }) => {
  return (
    <div style={styles.lineContainer}>
      <Text style={styles.saleDetails} value={title} />
      <Text style={{ fontSize: 13 }} value={content} />
    </div>
  );
};

const ViewOrderDetails: React.FC<IProps> = ({ setViewOrderDetails }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const saleDetails = useAppSelector(getSaleDetails);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);
  const location = useAppSelector(getLocation);
  const orderTotals = useAppSelector(getOrderTotals);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const ScreenComponent = deviceTypeMobile || extremelyShortScreen ? ScreenHero : ScreenFloating;
  const cartAsShadowItems = useAppSelector(getStagedPurchasesAsShadowItems);
  const saleType = useAppSelector(getSaleType);
  const discountedMoneyPrice = useFormattedCurrency({
    cents: saleDetails?.total || orderTotals?.discountedMoneyPrice,
  });
  const reorderableStagedPurchases = useAppSelector(
    state => state.ordering.currentOrder.stagedPurchases,
  );
  const surcharges = useAppSelector(getSurcharges);
  const surchargesWithCurrency = surcharges.map(surcharge => ({
    amount: useFormattedCurrency({ cents: surcharge.amount }),
    description: surcharge.description,
  }));
  const discount = useFormattedCurrency({ cents: (orderTotals?.orderMoneyDiscount || 0) * -1 });
  const member = useAppSelector(getMember);

  return (
    <React.Fragment>
      <ScreenComponent
        parentContainerStyle={deviceTypeMobile ? undefined : styles.desktopContentContainer}
      >
        <div
          style={combineStyles(
            styles.mainContainer,
            !deviceTypeMobile && styles.mainContainerDesktop,

            p('screen', ['backgroundColor']),
            p('completedOrderScreen', ['backgroundColor']),

            !deviceTypeMobile && p('completedOrderScreenDesktop', ['backgroundColor', 'boxShadow']),
            deviceTypeMobile && p('completedOrderScreenMobile', ['backgroundColor']),
          )}
        >
          <IoIosArrowBack
            fontSize={25}
            onClick={() => setViewOrderDetails(false)}
            style={styles.backArrow}
          />

          <div style={styles.parentContentsContainer}>
            <div style={styles.contentsContainer}>
              <div style={styles.topContainer}>
                {location && (
                  <div>
                    <Text themeKey="viewOrderDetailsTitle" style={styles.viewOrderDetailsTitle}>
                      {t(`saleType.${saleType}.name`).toUpperCase()}
                    </Text>
                    <Text themeKey="viewOrderDetailsLocation">{location.name.toUpperCase()}</Text>
                  </div>
                )}

                {member && (
                  <TouchableOpacity
                    onClick={() => {
                      dispatch(
                        setCurrentModal({
                          modalId: FAVOURITE_NAME_MODAL,
                          params: {
                            //@ts-ignore
                            historicalOrderItems: cartAsShadowItems,
                          },
                        }),
                      );
                    }}
                  >
                    <CiHeart fontSize={25} style={styles.backArrow} />
                  </TouchableOpacity>
                )}
              </div>
              <Line title={t('title.orderDetails.saleId')} content={saleDetails?.folioId} />

              {saleDetails?.orderDate && (
                <Line
                  title={t('title.orderDetails.date')}
                  content={moment(saleDetails.orderDate).format('DD/MM/YYYY')}
                />
              )}

              {saleDetails?.orderDate && (
                <Line
                  title={t('title.orderDetails.time')}
                  content={moment(saleDetails.orderDate).format('hh:mm')}
                />
              )}

              <div style={styles.columnHeaders}>
                <Text
                  themeKey="orderDetailsCart"
                  style={styles.columnHeaderDescription}
                  value={t('title.items')}
                />
                <Text
                  themeKey="orderDetailsCart"
                  style={styles.columnHeaderQty}
                  value={t('title.orderDetails.qty')}
                />
                <Text
                  themeKey="orderDetailsCart"
                  style={styles.columnHeaderPoints}
                  value={t('title.orderDetails.points')}
                />
                <Text
                  themeKey="orderDetailsCart"
                  style={styles.columnHeaderPrice}
                  value={t('title.orderDetails.price')}
                />
              </div>
              {stagedPurchases?.map((stagedPurchase, index) => (
                <OrderDetailsPurchases stagedPurchase={stagedPurchase} key={index} />
              ))}
              {surchargesWithCurrency.length || orderTotals?.orderMoneyDiscount ? (
                <div style={{ height: 15 }} />
              ) : null}
              {surchargesWithCurrency?.map(surcharge => (
                <div style={combineStyles(styles.bottomFragments, { marginTop: 0 })}>
                  <Text style={{ fontSize: 12 }} value={surcharge.description} />

                  <Text style={{ fontSize: 12 }}>{surcharge.amount}</Text>
                </div>
              ))}
              {orderTotals?.orderMoneyDiscount ? (
                <div style={combineStyles(styles.bottomFragments, { marginTop: 0 })}>
                  <Text style={{ fontSize: 12 }} value={t('totals.discount')} />

                  <Text style={{ fontSize: 12 }}>{discount}</Text>
                </div>
              ) : null}
              <div style={combineStyles(styles.bottomFragments, { marginTop: 15 })}>
                <Text style={styles.total} value={t('title.orderDetails.total')} />

                <Text style={{ fontSize: 13 }}>{discountedMoneyPrice}</Text>
              </div>

              {selectedPaymentMethods.length
                ? selectedPaymentMethods.map((selectedPaymentMethod, index) => (
                    <OrderDetailsSubPayments
                      selectedPaymentMethod={selectedPaymentMethod}
                      key={index}
                    />
                  ))
                : null}

              {!!orderTotals!.pointsAward && (
                <div style={styles.bottomFragments}>
                  <Text style={{ fontSize: 12 }} value={t('title.orderDetails.pointsEarned')} />

                  <Text style={{ fontSize: 12 }}>{orderTotals!.pointsAward.toFixed(2)}</Text>
                </div>
              )}

              {!!orderTotals?.rewardsAward && (
                <div style={styles.bottomFragments}>
                  <Text style={{ fontSize: 12 }} value={t('title.orderDetails.rewardsEarned')} />

                  <Text style={{ fontSize: 12 }}>
                    {toDollarString(orderTotals!.rewardsAward, { dollarSymbol: '' })}
                  </Text>
                </div>
              )}

              <StandardButton
                label={t('button.reorder')}
                themeKey="newOrderButton"
                containerStyle={styles.buttonContainer}
                onClick={async () => {
                  await dispatch(
                    startNewOrder({
                      preserveMember: true,
                    }),
                  );

                  dispatch(setReorderableItems(reorderableStagedPurchases));
                  dispatch(setOrderDate(null));
                  logEvent(EVENTS.START_NEW_ORDER);
                  // setTimeout(() => window.location.reload(), 0);
                }}
              />

              {member ? (
                <StandardButton
                  themeKey="viewOrderInvoiceButton"
                  label={t('button.resendInvoice')}
                  containerStyle={styles.title}
                  onClick={() => {
                    dispatch(fetchInvoice({ invoiceID: saleDetails?.transactionId! }))
                      .unwrap()
                      .then(() => enqueueSuccessSnackbar(t('snackbarMessages.success')))
                      .catch(err => enqueueErrorSnackbar(err.message));
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </ScreenComponent>

      {!deviceTypeMobile && <NewOrderButton />}
    </React.Fragment>
  );
};

const styles: Styles = {
  desktopContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 60,
  },
  mainContainer: {
    padding: 20,
  },
  mainContainerDesktop: {
    minWidth: 460,
  },
  backArrow: {
    cursor: 'pointer',
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  viewOrderDetailsTitle: {
    margin: '0 5px 0 0',
  },
  parentContentsContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px 0 0 0',
  },
  contentsContainer: {
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  saleDetails: {
    fontWeight: 'bold',
    margin: '0 5px 0 0 ',
    fontSize: 13,
  },

  headings: {
    fontWeight: 'bold',
  },

  bottomFragments: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  total: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  surcharge: {
    fontWeight: 'bold',
    fontSize: 10,
  },
  buttonContainer: {
    width: '90%',
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    alignSelf: 'center',
    marginTop: 20,
    height: 35,
  },
  title: {
    marginTop: 20,
  },
  columnHeaderDescription: {
    width: '50%',
  },
  columnHeaderQty: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  columnHeaderPoints: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
  },
  columnHeaderPrice: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  columnHeaders: {
    display: 'flex',
    marginTop: 30,
    fontWeight: 'bold',
  },
};

export default ViewOrderDetails;
