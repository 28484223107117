import { useTranslation } from 'react-i18next';
import React from 'react';
import Text from './Text';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

const OrderDetailsSubPayments = ({
  selectedPaymentMethod,
}: {
  selectedPaymentMethod: SubPayment;
}) => {
  const { t } = useTranslation();
  const price = useFormattedCurrency({ cents: selectedPaymentMethod.amount });
  return (
    <div>
      <Text style={{ fontSize: 12 }} value={t(`paymentMethod.${selectedPaymentMethod.method}`)} />
      <Text style={{ fontSize: 12 }}>{price}</Text>
    </div>
  );
};

export default OrderDetailsSubPayments;
