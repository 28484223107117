import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightFill } from 'react-icons/ri';

import { OrderingOperations } from 'polygon-ordering';

import StandardButton from './StandardButton';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import { setOrderDate } from '../slices/orderDate';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import combineStyles from '../utils/combineStyles';
import { setReorderableItems } from '../slices/reorderableItems';

const { startNewOrder } = OrderingOperations;

interface IProps {
  containerStyle?: React.CSSProperties;
}

const NewOrderButton: React.FC<IProps> = ({ containerStyle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  return (
    <div
      style={combineStyles(
        styles.bottomBar,
        deviceTypeMobile && {
          padding: 30,
          background: 'transparent',
        },
      )}
    >
      <StandardButton
        label={t('button.startNewOrder')}
        themeKey="newOrderButton"
        onClick={async () => {
          await dispatch(
            startNewOrder({
              preserveMember: true,
            }),
          );

          dispatch(setReorderableItems(null));
          dispatch(setOrderDate(null));
          logEvent(EVENTS.START_NEW_ORDER);
          // NOTE: this is bad practise
          // setTimeout(() => window.location.reload(), 0);
        }}
        containerStyle={styles.buttonContainer}
      />
    </div>
  );
};

export default NewOrderButton;

const styles: Styles = {
  buttonContainer: {
    borderRadius: 5,
    width: '100%',
    maxWidth: 400,
    padding: '0.925em 1.25em',
  },
  bottomBar: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    zIndex: 99,
    justifyContent: 'center',
    padding: '0.75em',
  },
};
