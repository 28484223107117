import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';
import { setSaleInputType } from 'libs/polygon-ordering/src/operations';
import Text from '../components/Text';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ModalFloatingHeader from './ModalFloatingHeader';

interface IProps {
  desktopMaxWidth?: number;
  desktopMinWidth?: number;
  desktopMaxHeight?: number;
  desktopMinHeight?: number;
  clickBackgroundToClose: boolean;
  containerStyle?: React.CSSProperties;
  setCurrentModal: (params: ModalParams) => void;
  desktopContainerStyle?: React.CSSProperties;
  backdropStyle?: React.CSSProperties;
  calloutOffsets?: CallOutOffSets | null;
  handleClose?: () => void;
  handleBack?: () => void;
  enableBackButton?: boolean;
  enableCloseButton?: boolean;
  title?: string;
  titleStyle?: React.CSSProperties;
}

const ModalCallout: React.FC<IProps> = ({
  clickBackgroundToClose,
  children,
  setCurrentModal,
  calloutOffsets,
  desktopMaxWidth,
  desktopMinWidth,
  desktopMaxHeight,
  desktopMinHeight,
  containerStyle,
  desktopContainerStyle,
  backdropStyle,
  handleClose,
  handleBack,
  enableBackButton,
  enableCloseButton,
  title,
  titleStyle,
}) => {
  const p = useAppSelector(getThemeLookup);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
      console.log('unmounting modal callout');
    };
  }, []);

  const close =
    handleClose ??
    (() => {
      setMounted(false);

      setTimeout(() => {
        setCurrentModal(null);
      }, 450);
    });

  return (
    <div>
      <CSSTransition in={mounted} timeout={450} classNames="rc-slide-left" unmountOnExit>
        <div
          onClick={clickBackgroundToClose ? close : undefined}
          style={combineStyles(styles.overlay, {
            paddingTop: calloutOffsets?.topOffset || 0,
            paddingRight: calloutOffsets?.rightOffset || 0,
          })}
        >
          <div
            style={combineStyles(
              styles.modalWrapper,
              Boolean(desktopMaxWidth) && { maxWidth: desktopMaxWidth },
              Boolean(desktopMinWidth) && { minWidth: desktopMinWidth },
              Boolean(desktopMaxHeight) && { maxHeight: desktopMaxHeight },
              Boolean(desktopMinHeight) && { minHeight: desktopMinHeight },
            )}
          >
            <div
              style={combineStyles(
                styles.modal,
                containerStyle,
                desktopContainerStyle,
                p('screen', ['backgroundColor']),
                p('modalDesktop', ['backgroundColor']),
                p('modalCallout', ['backgroundColor', 'boxShadow']),
              )}
              className="modalBody"
              onClick={event => event.stopPropagation()} // don't trigger the overlay's onClick event
            >
              {!!title && <Text style={titleStyle}>{title}</Text>}
              {children}
            </div>
            <ModalFloatingHeader
              onGoBack={handleBack}
              onClose={close}
              backButtonEnabled={enableBackButton}
              closeButtonEnabled={enableCloseButton}
            />
          </div>

          <div className="modal-overlay">
            <div style={p('modalOverlay', ['backgroundColor'])}></div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const styles: Styles = {
  overlay: {
    zIndex: 200,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',

    // overflowY: 'auto',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  modalWrapper: {
    maxHeight: '100%',
    height: '100%',
    display: 'flex',
    // alignItems: 'flex-start',
  },

  modal: {
    overflowY: 'auto',
    width: '100%',
    // maxHeight: '100%',
    //marginBottom: 10,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    // border: '1px solid lightgray', // not needed now that I have a box shadow
  },
};

export default ModalCallout;
