import { PaymentSense } from './gateways/paymentsense';
import { Windcave } from './gateways/windcave';

interface RedcatPaymentConfig {
  provider: string;
  config: PaymentSenseConfig | WindcaveConfig;
  interfaceDir?: string;
  debugLogs?: boolean;
}

export interface Gateway {
  inquire(txnId: number): Promise<any>;
  // pay(
  //   txnId: number,
  //   amount: number,
  //   cashOut: number,
  //   tipsEnabled: boolean
  // ): Promise<StatusRecord>;
  // writeReceipts(): Promise<void>;
}
export default class RedcatPaymentHandler {
  provider?: string;
  config?: PaymentSenseConfig | WindcaveConfig;
  interfaceDir?: string;
  debugLogs?: boolean;
  loggingHandler: any;
  paymentInstance: any;
  windcaveInstance: Windcave = new Windcave();
  paymentSenseInstance: PaymentSense = new PaymentSense();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  init(params: RedcatPaymentConfig) {
    this.provider = params?.provider;
    this.config = params?.config;
    this.interfaceDir = params?.interfaceDir;
    this.debugLogs = params?.debugLogs;
  }

  async inquire() {
    let result;
    console.log('---- I in the payment handler... -----');
    console.log(this.config);
    console.log('------------');

    const {
      user,
      hostAddress,
      paymentEnvironment,
      apiKey,
      terminalId,
      eposIdentifier,
    } = this.config!;
    switch (this.provider) {
      case 'dojo':
        result = await this.paymentSenseInstance.configurePaymentSense(
          hostAddress,
          paymentEnvironment,
          apiKey,
          terminalId,
          eposIdentifier
        );

        break;
      case 'windcave':
        result = await this.windcaveInstance.configurePaymentProvider(
          user,
          terminalId,
          paymentEnvironment,
          apiKey
        );

        break;
      default:
        console.log('Something went wrong when initalising Dojo');
        break;
    }
    return result;
  }

  async initatePayment(amount: number, productInfo?: any) {
    console.log(this.provider);
    switch (this.provider) {
      case 'dojo':
        return await this.paymentSenseInstance.initSale(amount);
      case 'windcave':
        return await this.windcaveInstance.initWindcaveSale(
          amount,
          productInfo
        );
      default:
        console.log('Something went wrong when we started the payment');
        break;
    }
  }

  async refundTransaction(url: string) {
    switch (this.provider) {
      case 'dojo':
        return await this.paymentInstance.refundTxn(url);
      default:
        console.log('Refund transaction failed, please check the endpoint');
        break;
    }
  }

  // TODO: tip amount

  async checkTransaction(ref?: string) {
    switch (this.provider) {
      case 'dojo':
        return await this.paymentSenseInstance.getTxn(ref!);
      case 'windcave':
        return await this.windcaveInstance.getTxn();
      default:
        console.log(
          'Cannot retrieve the transaction details, please check the endpoint'
        );
        break;
    }
  }

  async signatureVerification(verifyResponse: boolean, url?: string) {
    switch (this.provider) {
      case 'dojo':
        return await this.paymentSenseInstance.signatureVerification(
          url!,
          verifyResponse
        );
      case 'windcave':
        return await this.windcaveInstance.signatureVerification(
          verifyResponse
        );
      default:
        console.log(
          'Cannot retrieve the transaction details, please check the endpoint'
        );
        break;
    }
  }

  async cancelTransaction(url: string) {
    switch (this.provider) {
      case 'dojo':
        return await this.paymentSenseInstance.cancelTxn(url);
      default:
        console.log('Cancellation failed, please check the url endpoint');
        break;
    }
  }
}
