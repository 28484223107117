import React from 'react';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import ModalFloatingHeader from './ModalFloatingHeader';
import Text from './Text';
import { useAppSelector } from '../app/hooks';

interface IProps {
  title?: string;
  closeButtonLabel?: string;
  clickBackgroundToClose: boolean;
  previousModalId: string | null;
  setCurrentModal: (params: ModalParams) => void;
  locked?: boolean;
  hideFloatingHeader?: boolean;
  tallestPossible?: boolean;
  containerStyle?: React.CSSProperties;
  desktopContainerStyle?: React.CSSProperties;
  backdropStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  expandToMaxWidth?: boolean;
  desktopMaxWidth?: number;
  desktopMinWidth?: number;
  desktopMinHeight?: number;
  desktopMaxHeight?: number;
  backgroundClick?: () => void;
  handleClose?: () => void;
  titleStyle?: React.CSSProperties;
  closeButtonStyle?: React.CSSProperties;
  handleBack?: () => void;
  enableBackButton?: boolean;
  enableCloseButton?: boolean;
  headerContainerStyle?: React.CSSProperties;
  closeButtonLeft?: boolean;
}

const ModalDesktop: React.FC<IProps> = ({
  title,
  clickBackgroundToClose,
  children,
  previousModalId,
  setCurrentModal,
  desktopMaxWidth,
  desktopMinWidth,
  desktopMinHeight,
  desktopMaxHeight,
  containerStyle,
  desktopContainerStyle,
  backdropStyle,
  wrapperStyle,
  tallestPossible,
  expandToMaxWidth,
  locked,
  hideFloatingHeader,
  backgroundClick,
  titleStyle,
  handleClose,
  handleBack,
  enableBackButton,
  enableCloseButton,
  headerContainerStyle,
  closeButtonLeft,
}) => {
  const p = useAppSelector(getThemeLookup);
  const close = () => setCurrentModal(null);
  const goBack =
    handleBack ?? (() => previousModalId && setCurrentModal({ modalId: previousModalId }));

  return (
    <div
      style={combineStyles(
        styles.overlay,
        p('modalOverlay', ['backgroundColor']),
        p('modalOverlayDesktop', ['backgroundColor']),
        backdropStyle,
      )}
      onClick={
        backgroundClick ? backgroundClick : !locked && clickBackgroundToClose ? close : undefined
      }
    >
      <div
        style={combineStyles(
          styles.wrapper,
          tallestPossible && styles.tallestPossibleWrapper,
          expandToMaxWidth && styles.expandToMaxWidthWrapper,
          Boolean(desktopMaxWidth) && { maxWidth: desktopMaxWidth },
          Boolean(desktopMinWidth) && { minWidth: desktopMinWidth },
          Boolean(desktopMaxHeight) && { maxHeight: desktopMaxHeight },
          wrapperStyle,
        )}
        onClick={event => event.stopPropagation()} // don't trigger the overlay's onClick event
      >
        <div
          style={combineStyles(
            styles.modal,
            containerStyle,
            desktopContainerStyle,
            p('screen', ['backgroundColor']),
            p('modalDesktop', ['backgroundColor']),
            Boolean(desktopMinHeight) && { minHeight: desktopMinHeight },
            tallestPossible && styles.tallestPossibleModal,
          )}
        >
          {/* TODO: remove the modal title prop */}
          {title && (
            <Text
              themeKey="modalTitle"
              style={combineStyles(styles.title, titleStyle ? titleStyle : '')}
            >
              {title}
            </Text>
          )}
          {children}
        </div>
        {!hideFloatingHeader && (
          <ModalFloatingHeader
            onGoBack={goBack}
            onClose={handleClose ?? close}
            locked={locked}
            backButtonEnabled={enableBackButton}
            closeButtonEnabled={enableCloseButton}
            containerStyle={combineStyles(
              headerContainerStyle,
              Boolean(desktopMaxWidth) && { maxWidth: desktopMaxWidth },
              Boolean(desktopMinWidth) && { minWidth: desktopMinWidth },
            )}
            closeButtonLeft={closeButtonLeft}
          />
        )}
      </div>
    </div>
  );
};

const styles: Styles = {
  overlay: {
    zIndex: 200,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',

    overflowY: 'auto',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: 900,
    maxHeight: '90%',
    // position: 'relative',
    // width: '100%',
  },

  title: {
    textAlign: 'center',
  },

  modal: {
    borderRadius: 3,
    padding: '20px 0px',

    overflowY: 'auto',

    // maxHeight: 600,
  },

  tallestPossibleWrapper: {
    height: '100%',
    maxHeight: '100%',
    paddingBottom: 30,
    paddingTop: 20,
  },

  expandToMaxWidthWrapper: {
    width: '100%',
  },

  tallestPossibleModal: {
    flex: 1,
  },
};

export default ModalDesktop;
