import { createSelector } from '@reduxjs/toolkit';
import {
  checkTimeAvailability,
  checkCategoryAvailability,
} from '../utils/ordering/daypartMenuAvailability';
import calculateItemAvailabilityTime from '../utils/ordering/calculateDaypartMenuAvailabilityTime';
import getAllCategoryInfos from './getAllCategoryInfos';

export default (item: Item, mimCategoryId?: string, dayInFull?: boolean) =>
  createSelector(
    [getAllCategoryInfos],
    (allCategoryInfos): [boolean, string] => {
      if (!item) return [false, ''];
      const { start, end, days, availability } = item;
      const cat = (allCategoryInfos || []).find(c => c.id === mimCategoryId);

      if (!availability && (!cat || !cat?.availability)) return [true, ''];

      const [catAvailability] = checkCategoryAvailability(cat);
      if (!catAvailability) return [false, ''];

      return [
        checkTimeAvailability(availability, days, start, end),
        calculateItemAvailabilityTime(
          availability,
          days,
          start,
          end,
          dayInFull,
        ),
      ];
    },
  );
