import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import Text from './Text';

const { getEnergyDisplayTypes } = OrderingSelectors;

const EnergyText: React.FC<{ style?: React.CSSProperties }> = props => {
  const { t } = useTranslation();
  const displayTypes = useAppSelector(getEnergyDisplayTypes);

  // show average daily intake as calories or kilojoules or both depending on configs
  return (
    <Text {...props}>
      {t(
        `dailyIntake.${
          displayTypes?.includes('CALORIES' as ENERGY_TYPE)
            ? displayTypes.includes('KILOJOULES' as ENERGY_TYPE)
              ? 'kilojoulesAndCalories'
              : 'calories'
            : 'kilojoules'
        }`,
      )}
    </Text>
  );
};

export default EnergyText;
