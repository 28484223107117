import { sumObjectProperties } from '../misc';
import sortChoices from './sortChoices';

// nested mim item friendly recursive tallying function
// takes in a choice selections object and an item's choice sets and calculates the total prices for the item
// able to handle old-style and mim-style choice selections (ideally there should be one format)
export default function calculateChoiceSelectionsTotals(
  choiceSelections: NestedChoiceSelections | SDict<string[]>,
  choiceSets: ChoiceSet[],
  choiceOrderingMethod: ChoiceOrderingMethod,
): { moneyPrice: number; pointsPrice: number; pointsAward: number } {
  const sumsTotal = { moneyPrice: 0, pointsPrice: 0, pointsAward: 0 };

  Object.entries(choiceSelections ?? {}).forEach(([key, selections]) => {
    const choiceSet = choiceSets.find(cs => cs.key === key || cs.id === key);
    if (!choiceSet) return;
    const idBased =
      selections instanceof Array &&
      selections.every(selection => typeof selection === 'string');
    const nested =
      'nestedIngredients' in choiceSet &&
      (choiceSet as NestedChoiceSet).nestedIngredients;

    let choiceObjects = selections;
    if (idBased) {
      // old-style top-level choice with a list of ids instead of objects
      choiceObjects = (selections as string[])
        .map(id => choiceSet.choices.find(c => c.id === id))
        .filter(o => !!o);
    }

    if (nested) {
      // mim nested item selection
      // recursive case
      Object.entries(selections).forEach(([id, nestedSelections]) => {
        const nestedItem = choiceSet.choices.find(c => c.id === id);
        if (!nestedItem) return;
        // TODO: check nestedselections for validity
        const sums = calculateChoiceSelectionsTotals(
          nestedSelections as NestedChoiceSelections,
          (nestedItem as unknown as Item).choiceSets,
          choiceOrderingMethod,
        );

        sumsTotal.moneyPrice += sums.moneyPrice + nestedItem.baseMoneyPrice;
        // TODO: MiM points spec?
        sumsTotal.pointsPrice += sums.pointsPrice + nestedItem.basePointsPrice;
        sumsTotal.pointsAward += sums.pointsAward + nestedItem.basePointsAward;
      });
    } else {
      // normal top-level choice set using mim item selections
      const sums = sumObjectProperties(
        sortChoices(
          choiceObjects as ChoiceWithQuantity[],
          choiceOrderingMethod,
        ).slice(choiceSet.free ?? 0),
        ['baseMoneyPrice', 'basePointsPrice', 'basePointsAward'],
      );
      sumsTotal.moneyPrice += sums.baseMoneyPrice;
      sumsTotal.pointsPrice += sums.basePointsPrice;
      sumsTotal.pointsAward += sums.basePointsAward;
    }
  });

  return sumsTotal;
}
