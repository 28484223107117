import Text from './Text';
import React from 'react';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

const OrderDetailsPurchasesChoice = ({
  stagedPurchaseChoice,
}: {
  stagedPurchaseChoice: ChoiceWithQuantity;
}) => {
  const price = useFormattedCurrency({ cents: stagedPurchaseChoice.baseMoneyPrice });

  return (
    <div style={styles.items}>
      <Text
        themeKey="orderDetailsCart"
        style={styles.description}
        value={stagedPurchaseChoice.name}
      />
      <Text
        themeKey="orderDetailsCart"
        style={styles.qty}
        value={String(stagedPurchaseChoice.quantity)}
      />
      <Text themeKey="orderDetailsCart" style={styles.price} value={price} />
    </div>
  );
};

export default OrderDetailsPurchasesChoice;

const styles = {
  description: {
    width: '50%',
    paddingLeft: 10,
  },
  qty: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  points: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  price: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  items: {
    display: 'flex',
    opacity: 0.6,
  },
};
