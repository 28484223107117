// This file contains all the non-transformative selectors that run directly off of the config slice

import { createSelector } from 'reselect';

import getOrdering from './getOrdering';
import { SALE_TYPE } from '../constants/saleType';

export const getConfig = createSelector(
  [getOrdering],
  ordering => ordering.config,
);

export const getEnableGuestOrdering = createSelector(
  [getConfig],
  config => config.enableGuestOrdering,
);

export const getMobileRegexWhiteList = createSelector(
  [getConfig],
  config => config.mobileRegexWhiteList,
);

export const getApiHandlerReady = createSelector([getConfig], config =>
  Boolean(config.apiHandlerReady),
);

export const getAttempts = createSelector(
  [getConfig],
  config => config.attempts,
);

export const getUseChoiceSetDiscountMap = createSelector(
  [getConfig],
  config => config.choiceSetQuantityDiscount?.useChoiceSetDiscountMap,
);

export const getchoiceSetDiscountMap = createSelector(
  [getConfig, getUseChoiceSetDiscountMap],
  (config, usechoiceSetDiscountMap) =>
    usechoiceSetDiscountMap
      ? config.choiceSetQuantityDiscount?.useChoiceSetDiscountMap
      : undefined,
);

export const getChoiceSetDiscountConfig = createSelector(
  [getConfig, getUseChoiceSetDiscountMap],
  (config, useChoiceSetDiscountMap) =>
    !useChoiceSetDiscountMap
      ? config.choiceSetQuantityDiscount || undefined
      : undefined,
);

export const getCouponOrderingMethod = createSelector(
  [getConfig],
  config => config.couponOrderingMethod,
);

export const getEftposConfig = createSelector(
  [getConfig],
  config => config.eftpos,
);

export const getFlattenCategories = createSelector([getConfig], config =>
  Boolean(config.flattenCategories),
);

export const getChoiceOrderingMethod = createSelector(
  [getConfig],
  config => config.choiceOrderingMethod,
);

export const getFreeChoiceOrderingMethod = createSelector(
  [getConfig],
  config => config.freeChoiceOrderingMethod,
);

export const getEnabledSaleTypes = createSelector(
  [getConfig],
  config =>
    config.enabledSaleTypes || [
      SALE_TYPE.DELIVERY,
      SALE_TYPE.PICKUP,
      SALE_TYPE.TAKE_AWAY,
      SALE_TYPE.DINE_IN,
      SALE_TYPE.TABLE_ORDER,
    ],
);

export const getInternationalMobileAllowed = createSelector(
  [getConfig],
  config => Boolean(config.internationalMobileAllowed),
);

export const getLoyaltyRequiresVerification = createSelector(
  [getConfig],
  config => Boolean(config.loyaltyRequiresVerification),
);

export const getMenuSource = createSelector(
  [getConfig],
  config => config.menuSource,
);

export const getOrderingWindowPadding = createSelector(
  [getConfig],
  config => config.orderingWindowPadding,
);

export const getPaymentEnvironment = createSelector(
  [getConfig],
  config => config.paymentEnvironment,
);

export const getMerchantConfig = createSelector(
  [getConfig],
  config => config.merchant,
);

export const getProductConfig = createSelector(
  [getConfig],
  config => config.product,
);

export const getRememberCreditCard = createSelector([getConfig], config =>
  Boolean(config.rememberCreditCard),
);

export const getOrderingProvider = createSelector(
  [getConfig],
  config => config.orderingProvider,
);

export const getImportedGiftCardRegex = createSelector(
  [getConfig],
  config => config.importedGiftCardRegex,
);

export const getCardSets = createSelector(
  [getConfig],
  config => config.cardsets,
);

export const getEnableDineInNameMode = createSelector(
  [getConfig],
  config => config.enableDineInNameMode,
);

export const getEnableNonMemberPhoneNumberRequired = createSelector(
  [getConfig],
  config => config.enableNonMemberPhoneNumberRequired,
);

export const getExcludedChoiceSets = createSelector(
  [getConfig],
  config => config.excludedChoiceSets,
);

export const getBrands = createSelector(
  [getConfig],
  config => config.brands || [],
);

export const getShowHiddenLocations = createSelector(
  [getConfig],
  config => config.showHiddenLocations,
);

export const getEnableMultipleDeliveryEstimate = createSelector(
  [getConfig],
  config => config.enableMultipleDeliveryEstimate,
);

export const getEnableDuplicateSaleProtection = createSelector(
  [getConfig],
  config => config.enableDuplicateSaleProtection,
);

export const getSaleInputType = createSelector(
  [getConfig],
  config => config.saleInputType,
);

export const getEnableDynamicPaymentGatewayConfig = createSelector(
  [getConfig],
  config => config.enableDynamicPaymentGatewayConfig,
);

export const getPayLaterRules = createSelector(
  [getConfig],
  config => config.payLaterRules,
);

export const getHideNegativeIngredients = createSelector(
  [getConfig],
  config => config.hideNegativeIngredients,
);

export const getStockBalanceThresholds = createSelector(
  [getConfig],
  config => config.stockBalanceThresholds,
);

export const getLocaliseOrderTimes = createSelector(
  [getConfig],
  config => config.localiseOrderTimes,
);

export const getShowExpiredCoupons = createSelector(
  [getConfig],
  config => config.showExpiredCoupons,
);
export const getShowRedeemedCoupons = createSelector(
  [getConfig],
  config => config.showRedeemedCoupons,
);
export const getShowUnstartedCoupons = createSelector(
  [getConfig],
  config => config.showUnstartedCoupons,
);

export const getTagDisplayType = createSelector(
  [getConfig],
  config => config.tagDisplayType,
);

export const getEnergyDisplayTypes = createSelector(
  [getConfig],
  config => config.energyDisplayTypes,
);

export const getDisableAllBrandsMenuTab = createSelector(
  [getConfig],
  config => config.disableAllBrandsMenuTab,
);

export const getCurrencySymbol = createSelector(
  [getConfig],
  config => config.currencySymbol,
);

export const getLanguage = createSelector(
  [getConfig],
  config => config.language,
);

export const getStripeCurrency = createSelector(
  [getConfig],
  config => config.orderingCurrency,
);

export const getTaxName = createSelector([getConfig], config => config.taxName);

export const getCountryCode = createSelector(
  [getConfig],
  config => config.countryCode,
);

export const getPaymentCountryCode = createSelector(
  [getConfig],
  config => config.paymentCountryCode,
);

export const getEnabledUpsellByCheckout = createSelector(
  [getConfig],
  config => config.enableUpsellByCheckout,
);

export const getEnabledUpsellByItem = createSelector(
  [getConfig],
  config => config.enableUpsellByItem,
);

export const getUpsellDefaultTitle = createSelector(
  [getConfig],
  config => config.upsellDefaultTitle,
);
export const getUpsellDisplayLimit = createSelector(
  [getConfig],
  config => config.upsellDisplayLimit,
);
export const getResetEnableUpsellByCheckout = createSelector(
  [getConfig],
  config => config.resetEnableUpsellByCheckout,
);
export const getUpsellUnavailableDisplayType = createSelector(
  [getConfig],
  config => config.upsellItemHideGrey,
);

export const getDeviceId = createSelector(
  [getConfig],
  config => config.deviceId,
);

export const getDayPartMenuConfig = createSelector([getConfig], config => {
  {
    return config.dayPartMenu;
  }
});

export const getOAuthExtraInformationConfig = createSelector(
  [getConfig],
  config => {
    return config.oAuthDetails;
  },
);

export const getEnableStripeCvcRevalidation = createSelector(
  [getConfig],
  config => config.enableStripeCvcRevalidation,
);
