import React from 'react';
import ScreenHero from './ScreenHero';
import { useAppSelector } from '../app/hooks';
import { Col } from 'reactstrap';
import LoyaltyHeaderBar from './LoyaltyHeaderBar';
import LoyaltyTitle from './LoyaltyTitle';
import { useLocation } from 'react-router-dom';
import LoyaltySubTitle from './LoyaltySubTitle';
import { v4 as uuid } from 'uuid';
import getThemeLookup from '../selectors/getThemeLookup';
import LoyaltyTier from './LoyaltyTier';
import { REWARDS_SCREEN_ROUTE } from '../screens/RewardsScreen';
import { getLoyaltyDropdownMenu } from '../utils/getDropdownMenu';

const PrivateRouteLayout = ({ children }: { children: React.ReactNode }) => {
  const deviceType = useAppSelector(state => state.deviceType);
  const { pathname } = useLocation();
  const p = useAppSelector(getThemeLookup);
  const screens = getLoyaltyDropdownMenu();
  const enableTieredLoyalty = useAppSelector(state => state.config.enableTieredLoyalty);

  console.log('screens : ', screens);

  const findScreenDetails = (screens: any, path: string): any => {
    for (const element of screens) {
      if (element.path === path) {
        return element;
      }
      if (element.subRoutes) {
        const found = findScreenDetails(element.subRoutes, path);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const currentScreen = findScreenDetails(screens, pathname);

  return (
    <ScreenHero disablePSB={true}>
      <div
        className={deviceType === 1 ? ' pb-5' : ''}
        style={{
          backgroundColor:
            deviceType === 1
              ? p('loyaltyBackground', ['backgroundColor']).backgroundColor
              : p('loyaltyMobileBackground', ['backgroundColor']).backgroundColor,
          minHeight: '100%',
        }}
      >
        <div className="container ">
          <div className="row" style={{ paddingTop: deviceType === 1 ? 100 : '' }}>
            <Col lg="8" md="12" className="mx-auto" style={{ maxWidth: 500, padding: 0 }}>
              <div
                style={{
                  marginLeft: deviceType === 1 ? 20 : 0,
                  borderRadius: deviceType === 1 ? 5 : 0,
                  backgroundColor: p('loyaltyModals', ['backgroundColor']).backgroundColor,
                }}
              >
                {enableTieredLoyalty && pathname === REWARDS_SCREEN_ROUTE && <LoyaltyTier />}
                <div className={` pb-5 ${deviceType === 1 ? 'px-5 pt-3' : 'px-2'}`}>
                  {currentScreen && <LoyaltyTitle title={currentScreen.title!} />}

                  {children}
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </ScreenHero>
  );
};

export default PrivateRouteLayout;
