import React from 'react';
import { useAppSelector } from '../app/hooks';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import getThemeLookup from '../selectors/getThemeLookup';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { TEXT_PROPERTIES } from '../utils/theme';
import combineStyles from '../utils/combineStyles';

const FormDateFields = ({
  label,
  required = false,
  onChange,
  name,
  disabled,
  disableStyles = false,
  type,
  validate = false,
  value,
}: {
  required?: boolean;
  label: string;
  onChange: (e: any) => void;
  disableStyles?: boolean;
  name: string;
  disabled?: boolean;
  type: string;
  validate?: boolean;
  value?: any;
}) => {
  const p = useAppSelector(getThemeLookup);

  const useStyles = makeStyles(() => ({
    formControlLabel: {
      color: p('checkboxLabelColor', ['color']).color,
    },
    caretIcon: {
      color: `${p('caretColor', ['color']).color} !important`,
    },
    dropdown: {
      '& .MuiSelect-select': {
        //selected menu item color
        color: p('textColor', ['color']).color,
      },
    },
    datePickerActive: {
      '& .MuiInputBase-root': {
        color: p('textColor', ['color']).color,
      },
    },
    datePickerEmpty: {
      '& .MuiInputBase-root': {
        color: p('materialDatePickerPlaceholderColor', ['color']).color,
      },
    },
    datePickerErrors: {
      '& .MuiInputBase-root': {
        color: p('textColor', ['color']).color,
      },
    },
    errors: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${p('materialTextFieldErrorBorder', ['color']).color} !important`,
        },
      },
    },
    root: {
      '& ::-webkit-calendar-picker-indicator': {
        filter: `invert(${p('fieldIcons', ['color']).color})`,
      },
      //label - placeholder background color
      '& .MuiFormLabel-root': {
        backgroundColor: p('labelBackgroundColor', ['color']).color,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
      },

      //text field errors
      '&  .MuiFormHelperText-root.Mui-error': {
        backgroundColor: p('helperBackground', ['color']).color,
        color: p('helperColor', ['color']).color,
      },
      // focused color for textfield with variant='outlined'
      '& .MuiOutlinedInput-root': {
        //textfield background color
        background: p('fieldBackground', ['color']).color,

        //text field border unfocused
        '& fieldset': {
          borderColor: p('fieldDefaultBorder', ['color']).color,
        },
        //text field border focused
        '&.Mui-focused fieldset': {
          borderColor: p('fieldBorderFocus', ['color']).color,
        },
        //text field border hover
        '&:hover fieldset': {
          borderColor: p('fieldHoverBorderColor', ['color']).color,
        },
      },
    },

    checked: {},
  }));

  const muiStyles = useStyles();

  const sharedInputProps = {
    InputProps: {
      style: {
        //textfield text filled colors
        color: p('textColor', ['color']).color,
        ...p('defaultText', TEXT_PROPERTIES),
      },
    },
    InputLabelProps: {
      // style: {
      //   //textfield label - placeholder colors
      //   color: p('labelColor', ['color']).color,
      // },
      style: combineStyles(p('defaultText', TEXT_PROPERTIES), p('formLabel', TEXT_PROPERTIES)),
    },
  };

  const formik = useFormikContext<FormikFields>();

  return (
    <TextField
      size="small"
      name={name}
      label={label}
      type={type}
      required={required}
      InputProps={{
        ...sharedInputProps.InputProps,

        ...(validate
          ? {
              inputProps: {
                min: moment(new Date()).format('yyyy-MM-DDThh:mm'),
              },
            }
          : {}),
      }}
      InputLabelProps={{
        shrink: true,
        ...sharedInputProps.InputLabelProps,
      }}
      value={value ? value : formik.values[name as keyof FormikFields]}
      onChange={onChange}
      onBlur={formik.handleBlur}
      helperText={
        formik.touched[name as keyof FormikFields] && formik.errors[name as keyof FormikFields]
          ? formik.errors[name as keyof FormikFields]
          : ''
      }
      error={Boolean(
        formik.touched[name as keyof FormikFields] && formik.errors[name as keyof FormikFields],
      )}
      fullWidth
      disabled={disabled ? disabled : false}
      className={
        formik.errors[name as keyof FormikFields]
          ? muiStyles.datePickerErrors + ' ' + muiStyles.errors + ' ' + muiStyles.root
          : formik.values[name as keyof FormikFields] === '' ||
            formik.values[name as keyof FormikFields] === undefined
          ? muiStyles.datePickerEmpty + ' ' + muiStyles.root
          : muiStyles.datePickerActive + ' ' + muiStyles.root
      }
      variant="outlined"
      style={disableStyles ? {} : styles.inputField}
    />
  );
};

export default FormDateFields;

const styles: Styles = {
  inputField: {
    marginBottom: 10,
    marginTop: 10,
  },
};
