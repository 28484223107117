import React from 'react';
import lodash from 'lodash';
import TouchableOpacity from './TouchableOpacity';
import { RiMapPinFill } from 'react-icons/ri';
import Text from './Text';
import RedcatImage from './RedcatImage';
import { BRAND_ICON_PREFIX } from '../constants';
import { FormikValues, useFormikContext } from 'formik';
import { useAppSelector } from '../app/hooks';
import moment from 'moment';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { OrderingSelectors } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import { useTranslation } from 'react-i18next';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';

const { getKeyOrderPropertyUpdateInProgress } = OrderingSelectors;

const LocationListItem = ({
  location,
  onClick,
  activateBgColor,
}: {
  location: MapLocation;
  onClick?: () => void;
  activateBgColor: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const keyOrderPropertyUpdateInProgress = useAppSelector(getKeyOrderPropertyUpdateInProgress);
  const modalParams = useAppSelector(state => state.modalParams);

  const takingOrders =
    modalParams?.saleType === SALE_TYPE.CATERING
      ? true
      : location.online && location.takingOrders?.includes(modalParams?.saleType as SALE_TYPE);

  const isLocationClosed = moment().isAfter(location.closingTime);
  const minutesLeftToClosing = Math.abs(moment().diff(location.closingTime, 'minutes'));
  const orderingWindowStart = location.orderingWindowStart || location.openingTime;
  const orderingWindowEnd = location.orderingWindowEnd || location.closingTime;

  const start = moment(orderingWindowStart).format('LT');
  const end = moment(orderingWindowEnd).format('LT');

  const sortedBrands = lodash.sortBy(location.brands, 'sortOrder');

  return (
    <TouchableOpacity
      disabled={
        modalParams?.saleType === SALE_TYPE.CATERING || modalParams?.saleType === SALE_TYPE.DELIVERY
          ? false
          : !location.online || keyOrderPropertyUpdateInProgress
      }
      key={location.id}
      style={{
        border: p('locationListItem', ['border']).border || '1px solid #DFDFDF',
        margin: 10,
        padding: '1em 1em',
        borderRadius: 5,
        backgroundColor: activateBgColor
          ? p('activeLocationListItem', ['backgroundColor']).backgroundColor || '#DFDFDF'
          : 'white',
        transition: '0.25s all cubic-bezier(0.64, 0.01, 0.32, 0.97)',
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: 'flex',
          opacity:
            modalParams?.saleType === SALE_TYPE.CATERING ||
            modalParams?.saleType === SALE_TYPE.DELIVERY
              ? 1
              : takingOrders
              ? 1
              : values.listView
              ? '0.4'
              : 1,
        }}
      >
        <div style={{ flex: 1 }}>
          <Text style={{ fontWeight: 500 }} value={location.name} /> <br />
          <Text value={(location.address.combinedShort || '').trim()} themeKey="shortAddress" />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {(sortedBrands || []).map((brand: any, index) => (
              <div style={{ marginTop: 5 }} key={index}>
                <RedcatImage
                  containerStyle={styles.brandImage}
                  imagePath={brand.imagePath}
                  alt={brand.name}
                  size={25}
                  pathPrefix={BRAND_ICON_PREFIX}
                />
              </div>
            ))}
          </div>
          <div>
            {!takingOrders && (
              <Text
                style={combineStyles([
                  { color: '#f44336', fontSize: 12 },
                  p('closedLocation', TEXT_PROPERTIES),
                ])}
              >
                {isLocationClosed
                  ? `${t('locationOpensAt')} ${moment(location.openingTime).format('LT')}`
                  : `${t('locationNotTakingOrders')}`}
              </Text>
            )}
            {takingOrders && minutesLeftToClosing < 60 && (
              <Text style={{ color: '#f44336', fontSize: 12 }}>Closing soon</Text>
            )}
            {takingOrders && (
              <Text
                style={{
                  fontSize: 12,
                  color: '#9e9e9e',
                  marginLeft: minutesLeftToClosing < 60 ? 5 : 0,
                }}
              >
                {start} - {end}
              </Text>
            )}
          </div>
        </div>

        <div
          className="ml-3 text-center"
          style={{
            alignSelf: 'center',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            gap: '0.25em',
          }}
        >
          <RiMapPinFill
            size={20}
            color={p('mapSearchResultIcon', ['color']).color || 'indianred'}
          />
          <Text value={location.distance?.toFixed(2) + ' km'} style={{ fontSize: 12 }} />
        </div>
      </div>
    </TouchableOpacity>
  );
};

export default LocationListItem;

const styles = {
  brandImage: {
    marginRight: 10,
    borderRadius: 5,
    overflow: 'hidden',
  },
};
