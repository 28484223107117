import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LoyaltyWrapper from '../components/LoyaltyWrapper';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import PrivateRoute from './PrivateRoute';
import useRoutes, { INITIAL_SCREEN_ROUTE } from '../hooks/useRoutes';
import useInjection from '../hooks/useInjection';
import { PURCHASE_GIFT_CARD_ROUTE } from './PurchaseGiftCardScreen';
import { useAppSelector } from '../app/hooks';

const Routes = () => {
  const {
    defaultRoutes,
    loyaltyRoutes,
    loyaltyMigrationRoutes,
    unauthorisedRoutesWithWrapper,
    profileRoutes,
  } = useRoutes();

  useInjection();

  return (
    <Switch>
      {defaultRoutes?.map(route => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() => <route.component />}
        />
      ))}

      {[...loyaltyMigrationRoutes, ...unauthorisedRoutesWithWrapper]?.map(route => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() => (
            <LoyaltyWrapper>
              <route.component />
            </LoyaltyWrapper>
          )}
        />
      ))}

      {loyaltyRoutes?.map(route => (
        <PrivateRoute
          key={route.path}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}

      {profileRoutes.map(route => (
        <PrivateRoute
          key={route.path}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}
      <RedirectAndPreserveSearch to={INITIAL_SCREEN_ROUTE} />
    </Switch>
  );
};

export default Routes;
