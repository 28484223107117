import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Modal from '../components/Modal';
import { DESKTOP_MODAL_TYPES } from '../constants';
import TagAllergenDietary from '../components/TagAllergenDietary';
import RadioCheck from '../components/RadioCheck';
import Text from '../components/Text';
import StandardButton from '../components/StandardButton';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { setCurrentModalId } from '../slices/currentModalId';
import PerfectScrollbar from 'react-perfect-scrollbar';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import TouchableOpacity from '../components/TouchableOpacity';

const {
  getAllergenDietaryTags,
  getAvailableTags,
  getItemMaxPrice,
  getMenuFilterTags,
  getMenuFilterPrice,
  getCurrencySymbol,
} = OrderingSelectors;
const { setMenuFilterTags, setMenuFilterPrice } = OrderingOperations;

const MenuFiltersModal = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const allTags = useAppSelector(getAvailableTags);
  const maxPrice = useAppSelector(getItemMaxPrice);

  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  const { allergenTags, dietaryTags, customTags } = useAppSelector(state =>
    getAllergenDietaryTags(state, allTags),
  );
  const [filters, setFilters] = useState<number[] | null>(useAppSelector(getMenuFilterTags));
  const [filterMaxPrice, setFilterMaxPrice] = useState<number | null>(
    useAppSelector(getMenuFilterPrice),
  );
  const toggleFilter = (id: number) => {
    if (filters) {
      const idx = filters.findIndex(e => e === id);
      if (idx > -1) {
        setFilters(pre => (pre?.filter(e => e !== id) ? [...pre?.filter(e => e !== id)] : null));
      } else {
        setFilters(pre => (pre ? [...pre, id] : [id]));
      }
    } else {
      setFilters([id]);
    }
  };
  const applyFilters = () => {
    if (filters) {
      dispatch(setMenuFilterTags(filters));
    }

    if (filterMaxPrice !== null) {
      dispatch(setMenuFilterPrice(filterMaxPrice));
    }
    dispatch(setCurrentModalId(null));
  };

  const handlePriceFilter = (price: number) => {
    if (price !== null) setFilterMaxPrice(price);
  };

  const resetFilters = (title: string) => {
    if (title === 'Allergen') {
      const alergenFilterIds = allergenTags.map(allergenTag => allergenTag.recid);
      setFilters(pre => pre?.filter(e => !alergenFilterIds.includes(e)) || null);
    } else if (title === 'Dietary Requirements') {
      const dietaryFilterIds = dietaryTags.map(dietaryTag => dietaryTag.recid);
      setFilters(pre => pre?.filter(e => !dietaryFilterIds.includes(e)) || null);
    } else {
      const customFilterIds = customTags.map(customTag => customTag.recid);
      setFilters(pre => pre?.filter(e => !customFilterIds.includes(e)) || null);
    }
  };

  return (
    <Modal
      clickBackgroundToClose
      desktopModalType={DESKTOP_MODAL_TYPES.CALLOUT}
      desktopMaxWidth={440}
      desktopMinWidth={440}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      title={t('title.filter')}
      titleStyle={{
        padding: isMobileDevice ? '40px 40px 0px' : '40px 40px 10px',
        textAlign: 'center',
      }}
    >
      <PerfectScrollbar
        style={combineStyles(styles.scrollable, { padding: isMobileDevice ? 30 : 50 })}
      >
        {dietaryTags.length > 0 && (
          <FilterColumn
            tags={dietaryTags}
            filters={filters}
            title={t('title.dietaryTags')}
            filterToggle={toggleFilter}
            resetFilters={resetFilters}
          />
        )}
        {allergenTags.length > 0 && (
          <FilterColumn
            tags={allergenTags}
            filters={filters}
            title={t('title.allergenTags')}
            filterToggle={toggleFilter}
            resetFilters={resetFilters}
          />
        )}

        {customTags.length > 0 && (
          <FilterColumn
            tags={customTags}
            filters={filters}
            title={t('title.customTags')}
            filterToggle={toggleFilter}
            resetFilters={resetFilters}
          />
        )}

        <FilterPrice
          min={0}
          defaultValue={filterMaxPrice || 0}
          max={maxPrice}
          label={t('title.filterPrice')}
          onChange={handlePriceFilter}
          containerStyle={styles.filterPrice}
        />
      </PerfectScrollbar>
      <div
        style={combineStyles(
          { padding: isMobileDevice ? 30 : '15px 20px' },
          p('defaultBorder', ['borderTop']),
        )}
      >
        <StandardButton
          label={t('button.apply')}
          containerStyle={{ width: '100%' }}
          onClick={applyFilters}
        />
      </div>
    </Modal>
  );
};

export default MenuFiltersModal;
export const MENU_FILTERS_MODAL_ID = 'MENU_FILTERS_MODAL_ID';

const FilterColumn: React.FC<{
  tags: TagAllergenDietary[];
  filters: number[] | null;
  filterToggle: (id: number) => void;
  resetFilters: (title: string) => void;
  title?: string;
}> = ({ tags, filters, filterToggle, title, resetFilters }) => {
  const { t } = useTranslation();
  return (
    <>
      <div style={styles.filterColumn}>
        <Text themeKey="menuFilterTitle" style={{ textTransform: 'uppercase' }}>
          {title}
        </Text>
        <TouchableOpacity onClick={() => resetFilters(title!)} style={{ marginRight: 15 }}>
          <Text themeKey="clearAllButton">{t('button.clearAll')}</Text>
        </TouchableOpacity>
      </div>

      <div style={styles.filterColumnWrapper}>
        {tags.map(tag => {
          const checked = Boolean(filters && filters?.findIndex(f => f === tag.recid) > -1);
          return (
            <div style={styles.filterColumn} key={tag.recid}>
              <TagAllergenDietary
                option={tag}
                label={true}
                themeKey={tag.tag_type === 'CUSTOM' ? 'customTagWarning' : 'tagWarning'}
              />
              <RadioCheck
                checked={checked}
                onClick={() => {
                  filterToggle(tag.recid);
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const FilterPrice: React.FC<{
  min: number;
  max: number;
  defaultValue: number;
  onChange: (price: number) => void;
  label?: string;
  containerStyle?: React.CSSProperties;
}> = ({ min = 0, max, defaultValue, onChange, label, containerStyle }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const currency = useAppSelector(getCurrencySymbol);
  const [value, setValue] = useState(defaultValue);
  const handleChange = (newValue: number) => {
    setValue(newValue);
    onChange(newValue);
  };
  return (
    <Box sx={containerStyle}>
      <Text themeKey="menuItemName" style={{ textTransform: 'uppercase', width: '100%' }}>
        {t('title.filterPrice')}
      </Text>
      <Slider
        value={value / 100}
        aria-label="Default"
        valueLabelDisplay="auto"
        // TODO: GLOBALISATION
        valueLabelFormat={value => `${currency}${value}`}
        sx={{ color: p('menuFilterPrice', ['color']).color || 'red', width: '90%' }}
        marks={[
          { value: 0, label: '$0' },
          { value: max, label: `$${max}` },
        ]}
        name={label ? label : ''}
        min={min}
        max={max}
        onChange={(e, newValue) => handleChange((newValue as number) * 100)}
      />
    </Box>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    height: '100%',
    padding: 0,
    gap: 0,
  },
  modalMobileContainer: {
    padding: 0,
    gap: 0,
  },
  filterColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterColumnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 10,
    padding: 20,
  },
  scrollable: {
    overflowY: 'auto',
    flex: 1,
    width: '100%',
  },
  filterPrice: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    padding: '10px',
  },
};
