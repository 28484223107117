import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import HeaderBranding from './HeaderBranding';
import '../styles/HeaderBar.css';
import Hamburger from './Hamburger';
import { setCurrentDrawer } from '../slices/currentDrawer';
import { LEFT_MENU_DRAWER } from '../drawers/LeftMenuDrawer';
import MenuLocationDetails from './MenuLocationDetails';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import MenuSearchButton from './MenuSearchButton';
import { useLocation } from 'react-router-dom';
import { INITIAL_SCREEN_ROUTE, MENU_SCREEN_ROUTE } from '../hooks/useRoutes';
import Text from './Text';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { MENU_SEARCH_MODAL } from '../modals/MenuSearchModal';
import MenuFilterButton from './MenuFilterButton';
import { setCurrentModalId } from '../slices/currentModalId';
import { MENU_FILTERS_MODAL_ID } from '../modals/MenuFiltersModal';

interface IProps {
  themeKey: string;
}

const HeaderBar: React.FC<IProps> = ({ themeKey }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { pathname } = useLocation();

  return (
    <div
      style={combineStyles(
        styles.container,
        p('headerBar', ['padding', 'backgroundColor', 'boxShadow', 'gap']),
        p(themeKey, ['padding', 'backgroundColor']),
      )}
    >
      <Hamburger onClick={() => dispatch(setCurrentDrawer(LEFT_MENU_DRAWER))} />
      {pathname === INITIAL_SCREEN_ROUTE && deviceTypeMobile && (
        <Text themeKey="sectionTitleHeader" value={t('title.newOrder')} style={styles.newOrder} />
      )}
      {pathname === MENU_SCREEN_ROUTE && deviceTypeMobile && (
        <div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%,0)' }}>
          <MenuLocationDetails />
        </div>
      )}
      {!deviceTypeMobile && <HeaderBranding />}
      {pathname === MENU_SCREEN_ROUTE && deviceTypeMobile && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <MenuSearchButton
            toggleSearchActive={() => dispatch(setCurrentModal(MENU_SEARCH_MODAL))}
            containerStyle={{ paddingRight: 0 }}
          />
          <MenuFilterButton
            toggleFilterActive={() => dispatch(setCurrentModalId(MENU_FILTERS_MODAL_ID))}
            containerStyle={{ paddingRight: 0, marginLeft: 10 }}
          />
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  container: {
    width: '100%',
    height: 55,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 4,
    position: 'relative',
  },

  title: {
    textTransform: 'uppercase',
  },
  newOrder: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 35,
  },
};

export default HeaderBar;
