import { createAsyncThunk } from '@reduxjs/toolkit';
import debug from '../utils/debug';
import { setStringOverrides } from '../slices/config/stringOverrides';
import i18next from 'i18next'; // https://www.i18next.com/translation-function
import lodash from 'lodash';
import { LANGUAGES } from 'polygon-utils';
import getDisableLanguages from '../selectors/getDisableLanguages';
import { RootState } from 'store';

export const applyStringConfiguration = createAsyncThunk(
  'applyStringConfiguration',
  async (
    data: {
      overrides: any;
      language?: string;
      namespace?: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { overrides, namespace = 'translation' } = data;
    const disableLanguages = getDisableLanguages(getState() as RootState);
    const languageCodes = LANGUAGES.map(l => l.code) || ['en'];
    console.log('applyStringConfiguration', { overrides });

    try {
      if (
        disableLanguages &&
        overrides &&
        //@ts-ignore
        (!i18next.overrides || !lodash.isEqual(i18next.overrides, overrides))
      ) {
        const defaultLanguage = 'en';
        console.log('applying new i18next en overrides', { defaultLanguage });

        // NOTE: save overrides against i18next instance so
        // we can avoid applying the same ones every sync
        //@ts-ignore

        i18next.overrides = overrides;
        i18next.addResourceBundle(defaultLanguage, namespace, overrides, true, true);
      }

      if (!disableLanguages && overrides) {
        lodash.forEach(overrides, (override, language) => {
          if (languageCodes.includes(language as LanguageCode)) {
            console.log('applying new i18next multi-language overrides', {
              override,
              language,
            });
            i18next.addResourceBundle(language, namespace, override, true, true);
          }
        });
      }
      dispatch(setStringOverrides(overrides));
    } catch (e) {
      console.error('Problem applying string configuration', e);
      return rejectWithValue(e);
    }
  },
);
