import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiPencilFill,
  RiAddLine,
  RiAlertFill,
  RiCloseCircleFill,
  RiSearchLine,
} from 'react-icons/ri';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { OrderingSelectors, OrderingOperations, OrderingConstants } from 'polygon-ordering';
import { TEXT_PROPERTIES } from '../utils/theme';

import getThemeLookup from '../selectors/getThemeLookup';
import getInvalidOfferPresent from '../selectors/getInvalidOfferPresent';
import { OFFER_MODAL_ID } from '../modals/OfferModal';
import StandardButton from './StandardButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Text from './Text';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from '../utils/snackbar';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import DropDownWrapper from './DropDownWrapper';
import CouponsDropdown from './CouponsDropdown';

const { selectOffer, setPromoCode, applyPromoCode, updateSelectedPaymentMethods } =
  OrderingOperations;
const { FAILURE_REASON } = OrderingConstants;

const { getSelectedOffer, getOffers, getMember, getSelectedOfferAddedAutomatically } =
  OrderingSelectors;

const OrderOffer = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const selectedOffer = useAppSelector(getSelectedOffer);
  const offers = useAppSelector(getOffers);
  const invalidOfferPresent = useAppSelector(getInvalidOfferPresent);
  const dispatch = useAppDispatch();

  const offerPresent = Boolean(selectedOffer);

  return (
    <div>
      <StandardButton
        themeKey="orderPropertyButton"
        invisibleContainer
        label={
          offerPresent
            ? selectedOffer?.name.toUpperCase()
            : offers.length
            ? t('button.addOffer.offersPresent')
            : t('button.addOffer.offersAbsent')
        }
        RightIconComponent={offerPresent ? RiPencilFill : undefined}
        LeftIconComponent={invalidOfferPresent ? RiAlertFill : offerPresent ? undefined : RiAddLine}
        iconStyle={p('orderPropertyButtonIcon', ['color'])}
        onClick={() =>
          dispatch(setCurrentModal({ modalId: OFFER_MODAL_ID, preserveReturnModalDetails: true }))
        }
        labelStyle={p('orderOfferButton', TEXT_PROPERTIES)}
      />
      {offerPresent && (
        <div style={styles.messageContainer}>
          <Text themeKey="orderMessage" style={styles.messageText}>
            {selectedOffer!.description}
          </Text>
        </div>
      )}
    </div>
  );
};

const OrderOfferV2 = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const selectedOffer = useAppSelector(getSelectedOffer);
  const offers = useAppSelector(getOffers);
  const dispatch = useAppDispatch();
  const [dropDown, setDropDown] = useState(false);
  const member = useAppSelector(getMember);
  const selectedOfferAddedAutomatically = useAppSelector(getSelectedOfferAddedAutomatically);
  const userCanRemove = !selectedOfferAddedAutomatically || !selectedOffer?.enforced;

  useEffect(() => {
    if (selectedOffer && !userCanRemove) {
      setDropDown(false);
    }
  }, [selectedOffer]);

  const handleapplyPromoCode = async (promoCode: string) => {
    const code = promoCode.split(';').join('').split('?').join('').toUpperCase(); // remove ? and ; from the coupon hash
    await dispatch(applyPromoCode({ promocode: code }))
      .unwrap()
      .then(() => {
        setDropDown(false);
        enqueueSuccessSnackbar(t('applyPromoCode.success'));
        logEvent(EVENTS.APPLY_PROMOCODE);
      })
      .catch((err: any) => {
        if (err.reason === FAILURE_REASON.FETCH_FAILED) {
          enqueueErrorSnackbar(t('applyPromoCode.notFound'));
          logEvent(EVENTS.PROMOCODE_NOT_FOUND);
        } else {
          enqueueErrorSnackbar(t('applyPromoCode.failure'));
          logEvent(EVENTS.APPLY_PROMOCODE_FAILED, { label: err.reason });
        }
      });
  };

  const offersText = offers.length
    ? t('applyPromoCode.offersPresent')
    : t('applyPromoCode.offersAbsent');

  return (
    <div style={styles.container}>
      <DropDownWrapper
        text={
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Text>{!!selectedOffer ? selectedOffer!.name : offersText}</Text>
            {!!selectedOffer?.automatic && selectedOfferAddedAutomatically && (
              <Text themeKey="automaticOfferText">
                {t(
                  !selectedOffer?.enforced
                    ? 'title.modal.offer.offerAutomatic'
                    : 'title.modal.offer.offerEnforced',
                )}
              </Text>
            )}
          </div>
        }
        headerStyle={{ padding: wrapperPaddings }}
        childrenWrapperStyle={{ padding: '0 2.25em 1.2em' }}
        dropDown={dropDown}
        toggleCollapse={() => setDropDown(d => !d)}
        disabled={!userCanRemove}
      >
        {member && <CouponsDropdown />}
        <PromoCode placeholder={offersText} onClick={handleapplyPromoCode} />
      </DropDownWrapper>
    </div>
  );
};

const PromoCode = ({
  placeholder,
  onClick,
}: {
  placeholder: string;
  onClick: (code: string) => void;
}) => {
  const [textInput, setTextInput] = useState('');
  const handleClear = () => {
    setTextInput('');
  };
  const handleChange = (e: any) => {
    setTextInput(e.currentTarget.value || '');
  };
  return (
    <Paper sx={{ display: 'flex', alignItems: 'center' }} elevation={0} variant="outlined">
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        value={textInput}
        onChange={handleChange}
      />

      <IconButton
        type="button"
        onClick={handleClear}
        disabled={!Boolean(textInput.trim().length > 0)}
      >
        <RiCloseCircleFill size={20} color="silver" />
      </IconButton>

      {textInput.trim().length > 0 && (
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      )}
      {textInput.trim().length > 0 && (
        <IconButton
          type="button"
          onClick={() => onClick(textInput)}
          disabled={!Boolean(textInput.trim().length > 0)}
        >
          <RiSearchLine style={{ opacity: textInput.trim().length > 0 ? 1 : 0.5 }} />
        </IconButton>
      )}
    </Paper>
  );
};

export default OrderOfferV2;

const wrapperPaddings = '0 2.25em';

const styles: Styles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 5,
  },

  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  messageText: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '0.7rem',
    fontWeight: 200,
    textTransform: 'capitalize',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
