import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import SubSection from './SubSection';
import { setDesiredDeliveryAddress } from '../slices/desiredDeliveryAddress';
import TouchableOpacity from './TouchableOpacity';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import RadioCheck from './RadioCheck';

const { clearBuffer, deleteSavedAddress } = OrderingOperations;
const { getMember } = OrderingSelectors;

const SavedAddresses: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const effectiveDeliveryAddress = useAppSelector(state => state.desiredDeliveryAddress);
  return (
    <div>
      {member?.savedAddresses?.map((address, index) => (
        <div>
          <SubSection
            key={index}
            title={address.alias}
            subtitle={address.formatted_address}
            action={() => {
              if (
                effectiveDeliveryAddress?.street_name === address.street_name &&
                effectiveDeliveryAddress.locality === address.locality
              ) {
                dispatch(setDesiredDeliveryAddress(null));
              } else {
                dispatch(setDesiredDeliveryAddress(address as GoogleMapsLocation));
              }

              dispatch(clearBuffer({}));
            }}
            ActiveIcon={() => (<RadioCheck checked={
              effectiveDeliveryAddress?.street_name === address.street_name &&
              effectiveDeliveryAddress.locality === address.locality} 
              size={24}/>)
            }
          />

          <TouchableOpacity
            style={{
              fontSize: 12,
              opacity: 0.7,
            }}
            onClick={() =>
              dispatch(deleteSavedAddress({ id: address.id }))
                .unwrap()
                .then(() => enqueueSuccessSnackbar(t('message.savedAddressRemoved')))
                .catch(err => enqueueErrorSnackbar(t('message.savedAddressError')))
            }
          >
            {t('button.removeSavedAddress')}
          </TouchableOpacity>
        </div>
      ))}
    </div>
  );
};

export default SavedAddresses;
