import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TouchableOpacity from './TouchableOpacity';
import { MdOutlineArrowBackIosNew, MdClose } from 'react-icons/md';

const ModalBackButton = ({
  onClick,
  showCloseButton = false,
}: {
  onClick: () => void;
  showCloseButton?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const Icon = showCloseButton ? MdClose : MdOutlineArrowBackIosNew;
  return (
    <TouchableOpacity onClick={onClick}>
      <Icon size={25} />
    </TouchableOpacity>
  );
};

export default ModalBackButton;
