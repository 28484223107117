import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as lodash from 'lodash';
import * as Yup from 'yup';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import getProfileInitialValues from '../selectors/getProfileInitialValues';
import getMemberFields from '../selectors/getMemberFields';
import getMemberSchema from '../selectors/getMemberSchema';
import getProfile from '../selectors/getProfile';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { syncMember } from '../thunks/syncMember';
import ProfileFields from './ProfileFields';
import { Formik, Form, FormikHelpers } from 'formik';
import Text from '../components/Text';
import SendVerificationButton from '../components/SendVerificationButton';
import TouchableOpacity from '../components/TouchableOpacity';
import FormTextField from '../components/FormTextField';
import { OrderingSelectors } from 'polygon-ordering';

export const PROFILE_ROUTE = '/profile';

const { getMember } = OrderingSelectors;

const ProfileScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialVal = useAppSelector(getProfileInitialValues);
  const memberFields = useAppSelector(getMemberFields);
  const memberSchema = useAppSelector(getMemberSchema);
  const profile = useAppSelector(getProfile);
  const [mfaToken, setMfaToken] = useState(null);
  const member = useAppSelector(getMember);

  console.log({ memberSchema });
  const submit = (values: Partial<RegisterFields>, formikHelpers: FormikHelpers<FormikFields>) => {
    const { setSubmitting, resetForm } = formikHelpers;
    const path = '/api/v1/profile';
    // TODO add confirmation email
    console.log({ values });

    // Omit the 'Terms' field if it exists
    const sanitizedValues: any = lodash.omit(values, 'Terms');

    RedcatApiHandler.authorisedFetch({
      path,
      method: 'PUT',
      body: mfaToken
        ? //@ts-ignore
          {
            ...lodash.omit(sanitizedValues, 'mfaCode'),
            tfa_pin: sanitizedValues.mfaCode,
            tfa_token: mfaToken,
          }
        : lodash.omit(sanitizedValues, 'mfaCode'),
    })
      .then(response => {
        setMfaToken(null);

        if (response.token) {
          dispatch(syncMember());
        }
        if (response.success) {
          enqueueSuccessSnackbar(t('updateSuccess'));
          setSubmitting(false);
          resetForm({ values });
        }
      })
      .catch(err => {
        setSubmitting(false);
        const data = lodash.get(err, 'details.json', {});
        if (data.error_code === 3) {
          setMfaToken(data.additional_info);
          enqueueSuccessSnackbar('Verification PIN required');
        } else {
          enqueueErrorSnackbar(err);
        }
      });
  };

  const inputFeilds = (
    mfaToken
      ? memberFields?.visible.map((field: FormikFields) => ({
          ...field,
          editMode: 'hidden',
        }))
      : memberFields?.visible
  ) as FormikFields[];

  const disableForTermsField = (values: any) => {
    const termsField = inputFeilds.find(field => field.id === 'Terms');

    if (!termsField || termsField.required === false) return false;

    if (!values['Terms']) return true;

    return values['Terms'] === false;
  };

  const initVals = {
    ...initialVal.visible,
    mfaCode: '',
  };

  return (
    <div>
      {!profile?.verified ? (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Text value={t('profileVerificationText')} style={{ fontSize: 14 }} />
          <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          {mfaToken && (
            <TouchableOpacity
              onClick={() => setMfaToken(null)}
              style={{ position: 'absolute', top: -30, left: -5 }}
            >
              <IoIosArrowRoundBack
                style={{
                  fontSize: 28,
                }}
              />
            </TouchableOpacity>
          )}
          {!mfaToken && (
            <div style={{ padding: '20px 0' }}>
              <Text value={t('profileDetails')} style={{ fontSize: 16 }} />
            </div>
          )}
          {mfaToken && (
            <div style={{ padding: '30px 0 0' }}>
              <Text value={t('profileEmailVerification')} style={{ fontSize: 16 }} />
            </div>
          )}

          <Formik
            validationSchema={
              mfaToken
                ? memberSchema.visible?.shape({
                    mfaCode: Yup.string().required('Required').min(4, 'Too Short'),
                  })
                : memberSchema.visible
            }
            initialValues={initVals}
            //@ts-ignore
            onSubmit={submit}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleChange, isSubmitting, values, isValid, dirty }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {inputFeilds.map((field: FormikFields) => (
                    <ProfileFields
                      field={field}
                      key={field.id}
                      disabled={field?.overrides?.readOnly === 'updateProfileScreen'}
                      defaultValue={field.id === 'Email' ? member?.email : ''}
                    />
                  ))}
                  {Boolean(mfaToken) && (
                    <FormTextField
                      id="mfaCode"
                      name="mfaCode"
                      type="mfaCode"
                      label={t('field.signIn.mfaCode')}
                      required
                      onChange={handleChange}
                    />
                  )}
                  <div className="position-relative" style={{ marginTop: 20 }}>
                    <StandardButton
                      containerStyle={styles.button}
                      themeKey="loyaltyButton"
                      label={t('button.updateProfile')}
                      onClick={handleSubmit}
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        !dirty ||
                        lodash.isEqual(lodash.omit(values, 'Terms'), initVals) ||
                        disableForTermsField(values)
                      }
                      showSpinner={isSubmitting}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default ProfileScreen;
