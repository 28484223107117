import React from 'react';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { useTranslation } from 'react-i18next';
import { OrderingOperations } from 'polygon-ordering';
import { useAppDispatch } from '../app/hooks';

const { fetchInvoice } = OrderingOperations;

const ViewInvoice = ({ invoiceID }: { invoiceID: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div style={{ position: 'absolute', bottom: -15 }}>
      <TouchableOpacity
        onClick={() => {
          dispatch(fetchInvoice({ invoiceID }))
            .unwrap()
            .then(() => enqueueSuccessSnackbar(t('snackbarMessages.success')))
            .catch(err => enqueueErrorSnackbar(err.message));
        }}
      >
        <Text
          value={t('button.resendInvoice')}
          style={{ fontSize: 12, textDecoration: 'underline' }}
        />
      </TouchableOpacity>
    </div>
  );
};

export default ViewInvoice;
