import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { OrderingOperations, OrderingConstants } from 'polygon-ordering';

import debug from '../utils/debug';

import { setDeliveryEstimateInProgress } from '../slices/deliveryEstimateInProgress';
import { setDesiredDeliveryAddressNotFound } from '../slices/desiredDeliveryAddressNotFound';
import { setDeliveryEstimateFailed } from '../slices/deliveryEstimateFailed';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { enqueueErrorSnackbar } from '../utils/snackbar';

const { updateKeyOrderProperty } = OrderingOperations;

const { FAILURE_REASON, ASAP_TIME } = OrderingConstants;

export const performDeliveryEstimate = createAsyncThunk(
  'performDeliveryEstimate',
  async (
    data: {
      deliveryAddress: string;
      desiredDeliveryTime: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    let { deliveryAddress, desiredDeliveryTime } = data;
    debug('performing delivery estimate', { deliveryAddress, desiredDeliveryTime });

    try {
      if (
        desiredDeliveryTime &&
        desiredDeliveryTime !== ASAP_TIME &&
        moment() > moment(desiredDeliveryTime)
      ) {
        desiredDeliveryTime = ASAP_TIME;
      }

      dispatch(setDeliveryEstimateInProgress(true));

      await dispatch(
        updateKeyOrderProperty({
          autoApply: false,
          saleType: SALE_TYPE.DELIVERY,
          deliveryAddress,
          desiredDeliveryTime,
        }),
      ).unwrap();
      dispatch(setDeliveryEstimateInProgress(false));
    } catch (e) {
      console.error('Perform delivery estimate thunk failed', e);

      const { reason, userReason, systemReason } = e as any;

      enqueueErrorSnackbar(reason + '\n' + userReason + '\n' + systemReason);

      if (reason === FAILURE_REASON.ADDRESS_NOT_FOUND) {
        dispatch(setDesiredDeliveryAddressNotFound(true));
      }

      dispatch(setDeliveryEstimateFailed(true));
      dispatch(setDeliveryEstimateInProgress(false));

      return rejectWithValue(e);
    }
  },
);
