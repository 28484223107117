import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import SuperPaymentForm from '../components/SuperPaymentForm';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PurchaseGiftCardOptions from './PurchaseGiftCardOptions';
import Text from './Text';
import NonMemberGiftCardFields from './NonMemberGiftCardFields';
import { OrderingSelectors } from 'polygon-ordering';
import FormTextField from '../components/FormTextField';
import GiftCardDeliveryDetails from '../components/GiftCardDeliveryDetails';
import moment from 'moment';

const { getMember } = OrderingSelectors;

const PurchaseGiftCardForOthers: React.FC = () => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const maxGiftCardValue = useAppSelector(state => state.config.maxGiftCardValue);

  const isSameOrAfter = (startTime: any, endTime: any) => {
    return moment(startTime, 'HH:mm').isSameOrAfter(moment(endTime, 'HH:mm'));
  };

  const memberSchema = {
    Amount: Yup.string()
      .required(t('form.required'))
      .test('is-invalid-number', t('form.invalidNumber'), value => {
        if (value) return /^\d+$/.test(value);
        return false;
      })
      // TODO: Globalisation currency
      .test('is-less-than-max-amount', `Maximum $${maxGiftCardValue}`, value => {
        if (value) return Number(value) <= maxGiftCardValue;
        return false;
      }),
    RecipientName: Yup.string().required(t('form.required')),
    RecipientSurname: Yup.string().required(t('form.required')),
    RecipientEmail: Yup.string().required(t('form.required')),
    delivery: Yup.boolean(),
    SendTime: Yup.date().when('delivery', {
      is: true,
      then: Yup.date().test('SendTime', t('form.scheduleGiftcard'), (value: any) => {
        return isSameOrAfter(value, new Date());
      }),
    }),
  };

  const nonMemberSchema = {
    RecipientName: Yup.string().required(t('form.required')),
    RecipientSurname: Yup.string().required(t('form.required')),
    RecipientEmail: Yup.string().required(t('form.required')),
    FirstName: Yup.string().required(t('form.required')),
    LastName: Yup.string().required(t('form.required')),
    Email: Yup.string().required(t('form.required')),
    ConfirmEmail: Yup.string().required(t('form.required')),
    Amount: Yup.string()
      .required(t('form.required'))
      .test('is-invalid-number', t('form.invalidNumber'), value => {
        if (value) return /^\d+$/.test(value);
        return false;
      })
      .test('is-less-than-max-amount', `Maximum $${maxGiftCardValue}`, value => {
        if (value) return Number(value) <= maxGiftCardValue;
        return false;
      }),
    delivery: Yup.boolean(),
    SendTime: Yup.date().when('delivery', {
      is: true,
      then: Yup.date().test('SendTime', t('form.scheduleGiftcard'), (value: any) => {
        return isSameOrAfter(value, new Date());
      }),
    }),
  };
  const memberValidationSchema = Yup.object().shape(memberSchema);
  const nonMemberValidationSchema = Yup.object().shape(nonMemberSchema);

  const handleFormSubmit = () => {};

  const memberInitialValues = {
    Amount: '',
    RecipientName: '',
    RecipientSurname: '',
    RecipientEmail: '',
    Memo: '',
    SendTime: '',
    timezone: 'Australia/Melbourne',
    delivery: false,
  };
  const nonMemberInitialValues = {
    Amount: '',
    FirstName: '',
    LastName: '',
    Email: '',
    ConfirmEmail: '',
    RecipientName: '',
    RecipientSurname: '',
    RecipientEmail: '',
    Memo: '',
    SendTime: '',
    timezone: 'Australia/Melbourne',
    delivery: false,
  };

  const fields = [
    {
      name: 'RecipientName',
      type: 'text',
      label: t('placeholder.firstName'),
    },
    {
      name: 'RecipientSurname',
      type: 'text',
      label: t('placeholder.lastName'),
    },

    {
      name: 'RecipientEmail',
      type: 'email',
      label: t('placeholder.email'),
    },
    {
      name: 'Memo',
      type: 'text',
      label: t('placeholder.message'),
    },
  ];

  return (
    <Formik
      validationSchema={member ? memberValidationSchema : nonMemberValidationSchema}
      initialValues={member ? memberInitialValues : nonMemberInitialValues}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, handleChange }) => {
        return (
          <Form onSubmit={handleSubmit}>
            {!member && <NonMemberGiftCardFields />}
            <Text
              value={t('recipientDetails')}
              style={{ fontSize: 15 }}
              themeKey="recipientDetails"
            />
            {fields.map(field => (
              <FormTextField
                key={field.name}
                type={field.type}
                name={field.name}
                label={field.label}
                onChange={handleChange}
              />
            ))}

            <GiftCardDeliveryDetails />

            <PurchaseGiftCardOptions />
            <SuperPaymentForm
              route={
                member ? 'memberPurchaseGiftCardForOthers' : 'nonMemberPurchaseGiftCardForOthers'
              }
              notRequired={['Memo', 'SendTime']}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PurchaseGiftCardForOthers;
